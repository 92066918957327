export const intlMessagesEn = {
  'title.project': 'Jumpstart',

  'action.accept': 'Accept',
  'action.activate': 'Activate',
  'action.add': 'Add',
  'action.assign': 'Assign',
  'action.available': 'Available',
  'action.complete': 'Complete',
  'action.cancel': 'Cancel',
  'action.cardCode.create': 'Create',
  'action.cimbDashboardFailRequest.createUpsert': 'Create Upsert',
  'action.continue': 'Continue',
  'action.copyToClipboard': 'Copy to clipboard',
  'action.deactivate': 'Deactivate',
  'action.decline': 'Decline',
  'action.deliver': 'Deliver',
  'action.detail': 'Detail',
  'action.down': '↓',
  'action.downloadCsv': 'Download CSV',
  'action.dynamicsFailRequest.createUpsert': 'Create Upsert',
  'action.edit': 'Edit',
  'action.edit.profile.self': 'Edit my profile',
  'action.fillMachineStockIn': 'Fill Stock In',
  'action.fillMachineStockOut': 'Fill Stock Out',
  'action.fill.shopHubStock': 'Fill Stock',
  'action.finish': 'Finish',
  'action.generatePdf': 'Generate PDF',
  'action.insert.machineStockIn': 'Insert Stock In',
  'action.insert.machineStockOut': 'Insert Stock Out',
  'action.insert.shopHubStock': 'Insert Stock',
  'action.login.submit': 'Sign in',
  'action.logout': 'Sign out',
  'action.machine.edit': 'Edit Machine',
  'action.machine.set': 'Set Machine',
  'action.machine.unload': 'Unload',
  'action.machineDraft.convertMachine': 'Convert to Machine',
  'action.maps': 'Maps',
  'action.outOfReach': 'Out Of Reach',
  'action.profile.self': 'My profile',
  'action.report': 'Report',
  'action.reassign': 'Reassign',
  'action.retry': 'Retry',
  'action.select': 'Select',
  'action.shopManualNotification.add': 'Add Notification',
  'action.shopThirdPartyPromo.add': 'Add Third Party Promo',
  'action.soldOut': 'Sold Out',
  'action.start': 'Start',
  'action.up': '↑',
  'action.user.add': 'Add',
  'action.userMapping': 'Mapping',
  'action.shopHubMenu.downloadFormatExcel': 'Download Format Excel',

  'button.toggle': 'Toggle Layout Loading',

  'dayOfWeek.friday': 'Friday',
  'dayOfWeek.monday': 'Monday',
  'dayOfWeek.saturday': 'Saturday',
  'dayOfWeek.sunday': 'Sunday',
  'dayOfWeek.thursday': 'Thursday',
  'dayOfWeek.tuesday': 'Tuesday',
  'dayOfWeek.wednesday': 'Wednesday',

  'form.action.add': 'Add',
  'form.action.addCompanyRewardRule': 'Add Reward Rule',
  'form.action.addCustomerDepartment': 'Add Employee',
  'form.action.addDepartment': 'Add Department',
  'form.action.assign': 'Assign',
  'form.action.back': 'Back',
  'form.action.cancel': 'Cancel',
  'form.action.decline': 'Decline',
  'form.action.decline.submit': 'Submit Decline',
  'form.action.delete': 'Delete',
  'form.action.deleteAll': 'Delete All',
  'form.action.deleteCurrentPage': 'Delete Current Page',
  'form.action.download': 'Download',
  'form.action.downloadAll': 'Download All',
  'form.action.edit': 'Edit',
  'form.action.filter': 'Filter',
  'form.action.finish': 'Finish',
  'form.action.insert.menuGroup': 'Insert Menu',
  'form.action.next': 'Next',
  'form.action.order.process': 'Process Order',
  'form.action.remove': 'Remove',
  'form.action.reset': 'Reset',
  'form.action.resetAll': 'Reset All',
  'form.action.resetCurrentPage': 'Reset Current Page',
  'form.action.resetPassword': 'Reset Password',
  'form.action.run': 'Run',
  'form.action.save': 'Save',
  'form.action.stop': 'Stop',
  'form.action.stopAll': 'Stop All',
  'form.action.stopCurrentPage': 'Stop Current Page',
  'form.action.updateLatitudeLongitude': 'Update Latitude Longitude',
  'form.action.uploadCustomerDepartment': 'Upload Employee',
  'form.action.uploadMachinePayment': 'Upload Payment',
  'form.action.uploadRfidCard': 'Upload Rfid Card',
  'form.action.downloadAllStockIn': 'Download All Stock In',
  'form.action.downloadAllStockOut': 'Download All Stock Out',

  'generic.label.active': 'Active',
  'generic.label.additionalPrice': 'Additional Price',
  'generic.label.address': 'Address',
  'generic.label.amount': 'Amount',
  'generic.label.amountType': 'Amount Type',
  'generic.label.apiKey': 'Api Key',
  'generic.label.clientId': 'Client ID',
  'generic.label.channelId': 'Channel ID',
  'generic.label.createdAt': 'Created At',
  'generic.label.customerIds': 'Customer Id',
  'generic.label.dateAvailability': 'Date Availability',
  'generic.label.description': 'Description',
  'generic.label.duration': 'Duration',
  'generic.label.endDate': 'End Date',
  'generic.label.endHour': 'End Hour',
  'generic.label.hot': 'Hot',
  'generic.label.hotTemperature': 'Hot Temperature',
  'generic.label.ice': 'Ice',
  'generic.label.iceTemperature': 'Ice Temperature',
  'generic.label.id': 'ID',
  'generic.label.image': 'Image',
  'generic.label.imageFile': 'Image File',
  'generic.label.imageUrl': 'Image URL',
  'generic.label.initialName': 'Initial Name',
  'generic.label.machines': 'Machines',
  'generic.label.machinePaymentId': 'Machine Payment ID',
  'generic.label.machineProductType': 'Product Type',
  'generic.label.machineQr': 'Machine QR',
  'generic.label.machineTypeDisplayId': 'Machine Type Display ID',
  'generic.label.merchantId': 'Merchant ID',
  'generic.label.mid': 'MID',
  'generic.label.storeID': 'store ID',
  'generic.label.posID': 'pos ID',
  'generic.label.missedAt': 'Missed At',
  'generic.label.name': 'Name',
  'generic.label.none': 'None',
  'generic.label.notes': 'Notes',
  'generic.label.notSet': '-',
  'generic.label.payment': 'Payment',
  'generic.label.paymentProvider': 'Payment Method',
  'generic.label.phoneNo': 'Phone Number',
  'generic.label.jsRewardBalance': 'Js Reward Balance',
  'generic.label.price': 'Price',
  'generic.label.priority': 'Priority',
  'generic.label.products': 'Products',
  'generic.label.productCode': 'Product Code',
  'generic.label.productId': 'Product ID',
  'generic.label.productName': 'Product Name',
  'generic.label.recurring': 'Is Recurring',
  'generic.label.rewardCutOffTime.daily': 'Reward Cut Off Time (Daily)',
  'generic.label.rewardCutOffTime.monthly': 'Reward Cut Off Time (Monthly)',
  'generic.label.rewardCutOffTime.weekly': 'Reward Cut Off Time (Weekly)',
  'generic.label.rewardRule': 'Reward Rule',
  'generic.label.rfidCardNo': 'Card No.',
  'generic.label.rfidCardNos': 'Card No(s)',
  'generic.label.schedules': 'Schedules',
  'generic.label.shopProductId': 'Shop Product ID',
  'generic.label.shopProductName': 'Shop Product Name',
  'generic.label.shopProductDisplayCategory': 'Shop Product Display Category',
  'generic.label.shopProductDisplayCategoryDescription': 'Shop Product Display Category Description',
  'generic.label.shopProductDisplayCategoryId': 'Shop Product Display Category Id',
  'generic.label.shopProductDisplayCategoryName': 'Shop Product Display Category Name',
  'generic.label.shopProductDisplayCategoryStatus': 'Shop Product Display Category Status',
  'generic.label.storeExtId': 'Store Ext ID',
  'generic.label.startDate': 'Start Date',
  'generic.label.startHour': 'Start Hour',
  'generic.label.status': 'Status',
  'generic.label.sugar': 'Sugar',
  'generic.label.temperature': 'Temperature',
  'generic.label.terminalId': 'Terminal ID',
  'generic.label.tid': 'Terminal ID',
  'generic.label.usage': ' Usage',
  'generic.label.usageLimit': 'Limit',
  'generic.label.usageType': 'Usage Type',
  'generic.label.username': 'Username',
  'generic.label.variantName': 'Variant Name',
  'generic.label.variantUnitName': 'Variant Unit Name',

  'label.assignTask.assigneeId': 'Assignee',
  'label.cardCode.commaSeparatedCodesAndProductIds': 'Comma Separated Codes and Product Ids',
  'label.cimbDashboardFailRequest.ids': 'CIMB Dashboard Fail Request IDs',
  'label.cluster.name': 'Name',
  'label.currency': 'Rp',
  'label.delivery.option': 'Delivery Option',
  'generic.label.department': 'Department',
  'label.dynamicsFailRequest.ids': 'Dynamics Fail Request IDs',
  'label.generic.active': 'Active?',
  'label.generic.isThirdPartyDataTransfer': 'Need Integrate to Third Party?',
  'label.generic.waitingForAltitude': 'Cannot activate yet, waiting for Altitude',
  'label.hub.additionalInformation': 'Additional Information',
  'label.hub.address': 'Address',
  'label.hub.buildingName': 'Building Name',
  'label.hub.city': 'City',
  'label.hub.close': 'Close',
  'label.hub.cluster': 'Cluster',
  'label.hub.commissionFee': 'Commission Fee',
  'label.hub.companyName': 'Company Name',
  'label.hub.corporateDiscount': 'Corporate Discount',
  'label.hub.district': 'District',
  'label.hub.feePerCup': 'Fee Per Cup',
  'label.hub.floor': 'Floor',
  'label.hub.hubType': 'Location Type',
  'label.hub.isOddEvenRationing': 'Odd/Even Rationing?',
  'label.hub.latitude': 'Latitude',
  'label.hub.longitude': 'Longitude',
  'label.hub.minimumAmount': 'Min. Amount',
  'label.hub.monthlyRentalFee': 'Monthly Rental Fee',
  'label.hub.name': 'Location Name',
  'label.hub.open': 'Open',
  'label.hub.ownership': 'Ownership',
  'label.hub.paymentTerms': 'Payment Terms',
  'label.hub.postalCode': 'Postal Code',
  'label.hub.priceSettingOption': 'Price Setting',
  'label.hub.priceSettingType': 'Price Setting',
  'label.hub.priceSettingValue': 'Amount',
  'label.hub.province': 'Province',
  'label.hub.rentType': 'Location Arrangement',
  'label.hub.revenue': 'Revenue',
  'label.hub.runningSchedule': 'Running Schedule',
  'label.hub.sales': 'Sales',
  'label.hub.subDistrict': 'Subdistrict',
  'label.hub.subsidy': 'Subsidy',
  'label.hub.targetCup': 'Target Cup',
  'label.hub.targetRevenue': 'Target Revenue',
  'label.hub.timezone': 'Timezone',
  'label.hubType.active': 'Active',
  'label.hubType.description': 'Description',
  'label.hubType.id': 'ID',
  'label.hubType.name': 'Name',
  'label.hubType.status': 'Status',
  'label.ingredient.active': 'Active',
  'label.ingredient.name': 'Name',
  'label.ingredient.orderNr': 'Display ID',
  'label.ingredient.packagingQuantity': 'Packaging Quantity',
  'label.ingredient.remark': 'Remark',
  'label.ingredient.unit': 'Unit',
  'label.instruction.assignTask': 'Assign Task',
  'label.instruction.finishInvestigation': 'Finish Investigation',
  'label.instruction.lock': 'Lock',
  'label.instruction.openDoor': 'Open Door',
  'label.instruction.reboot': 'Reboot',
  'label.instruction.rinsing': 'Rinsing',
  'label.instruction.unlock': 'Unlock',
  'label.latteConfig.carRefillTime': 'Car Refill Time',
  'label.latteConfig.minTravelTime': 'Min Travel Time',
  'label.latteConfig.carRefillerMaxDistance': 'Car Refiller Max Distance',
  'label.latteConfig.refillerMaxGallon': 'Refiller Max Gallon',
  'label.latteConfig.refillerRefillTime': 'Refiller Refill Time',
  'label.latteConfig.refillerTravelSpeed': 'Refiller Travel Speed',
  'label.latteConfig.carRefillerTravelSpeed': 'Car Refiller Travel Speed',
  'label.latteConfig.minPercentageCapacity': 'Percentage Capacity',
  'label.leave.date': 'Date',
  'label.leave.date.picker': 'Select leave date',
  'label.leave.manager': 'Manager',
  'label.leave.note': 'Note',
  'label.leave.state': 'State',
  'label.leave.type': 'Type',
  'label.leave.user': 'User',
  'label.login.password': 'Password',
  'label.login.username': 'Username',
  'label.loyaltyPoint.point': 'pts',
  'label.machine.active': 'Active',
  'label.machine.address': 'Address',
  'label.machine.connected': 'Connection status',
  'label.machine.factoryNumber': 'Factory Number',
  'label.machine.history.message': 'Data',
  'label.machine.history.type': 'Type',
  'label.machine.history.updatedTime': 'Updated Time',
  'label.machine.history.user': 'User',
  'label.machine.id': 'ID',
  'label.machine.info.datetime': 'Date & Time',
  'label.machine.info.fatalError': 'Fatal Error',
  'label.machine.info.machineSet': 'Set',
  'label.machine.info.notes': 'Notes',
  'label.machine.info.persistenceError': 'Persistence Error',
  'label.machine.info.status': 'Status',
  'label.machine.isErrorAssigned': 'Operational status',
  'label.machine.lastError': 'Last error',
  'label.machine.lastErrorAt': 'Last error at',
  'label.machine.latitude': 'Latitude',
  'label.machine.locationArea': 'Area',
  'label.machine.locationName': 'Location Name',
  'label.machine.locationType': 'Location Type',
  'label.machine.longitude': 'Longitude',
  'label.machine.machineType': 'Machine Type',
  'label.machine.mid': 'Machine ID',
  'label.machine.midtrans': 'Midtrans Account',
  'label.machine.name': 'Name',
  'label.machine.owner': 'Owner',
  'label.machine.physicalCondition': 'Physical Condition',
  'label.machine.postalCode': 'Postal Code',
  'label.machine.product.active': 'Active',
  'label.machine.product.menuGroup': 'Menu',
  'label.machine.product.price': 'Price',
  'label.machine.product.updatedTime': 'Updated Time',
  'label.machine.product.user': 'Updated by',
  'label.machine.productSet': 'Menu Set',
  'label.machine.running': 'Running Status',
  'label.machine.runningSchedule': 'Running Schedule',
  'label.machine.sales': 'Sales',
  'label.machine.savedLatitude': 'Saved Latitude',
  'label.machine.savedLongitude': 'Saved Longitude',
  'label.machine.serverinstructionlog.command': 'Command',
  'label.machine.serverinstructionlog.datetime': 'Date & Time',
  'label.machine.serverinstructionlog.payload': 'Payload',
  'label.machine.serverinstructionlog.vmcNo': 'VMC No',
  'label.machine.simcardNo': 'SIM Card No',
  'label.machine.status': 'Status',
  'label.machine.tid': 'TID',
  'label.machine.timezone': 'Timezone',
  'label.machine.vmcNo': 'VMC No',
  'label.machineError.code': 'Error Code',
  'label.machineError.description': 'Description',
  'label.machineError.urgency': 'Urgency',
  'label.machineDraft.factoryNumber': 'Factory Number',
  'label.machineDraft.machineType': 'Machine Type',
  'label.machineDraft.owner': 'Owner',
  'label.machineDraft.physicalCondition': 'Physical Condition',
  'label.machineDraft.vmcNo': 'VMC No',
  'label.machineHub.loadedAt': 'Load At',
  'label.machineHub.machineId': 'VMC NO',
  'label.machineHub.merchantId': 'MID',
  'label.machineHub.midtransId': 'Midtrans Account',
  'label.machineHub.simcardNo': 'Sim Card No',
  'label.machineHub.terminalId': 'TID',
  'label.machineHub.unLoadedAt': 'Unload At',
  'label.machineStockIn.currentStock': 'Current Stock In',
  'label.machineStockIn.important': 'Important',
  'label.machineStockIn.ingredient': 'Ingredient',
  'label.machineStockIn.maxStock': 'Max Stock In',
  'label.machineStockIn.minStock': 'Min Stock In',
  'label.machineStockOut.currentStock': 'Current Stock',
  'label.machineStockOut.infinite': 'Infinite?',
  'label.machineStockOut.ingredient': 'Ingredient',
  'label.machineStockOut.maxStock': 'Max Stock',
  'label.machineStockOut.minStock': 'Min Stock',
  'label.machineStockOut.packaging': 'Packaging',
  'label.machineType.active': 'Active',
  'label.machineType.commandType': 'Command Type',
  'label.machineType.description': 'Description',
  'label.machineType.ingredient': 'Ingredient',
  'label.machineType.initialName': 'Initial Name',
  'label.machineType.aiRule': 'AI Rule',
  'label.machineType.name': 'Name',
  'label.machineType.machineTypeDisplay': 'Machine Type Display',
  'label.machineType.stockLevelCup.medium': 'Medium',
  'label.machineType.stockLevelCup.maxCup': 'Max Cup',
  'label.machineType.stockLevelCup.minCup': 'Min Cup',
  'label.machineType.stockLevelCup.notes': '* medium will be between low and high',
  'label.machineTypeStock.ingredient.name': 'Ingredient Name',
  'label.machineTypeStock.maxStock.low': 'Max Stock Low',
  'label.machineTypeStock.maxStock.medium': 'Max Stock Medium',
  'label.machineTypeStock.maxStock.high': 'Max Stock High',
  'label.machineTypeStock.maxStockIn.low': 'Max Stock Low',
  'label.machineTypeStock.maxStockIn.medium': 'Max Stock Medium',
  'label.machineTypeStock.maxStockIn.high': 'Max Stock High',
  'label.machineTypeStock.maxStockOut.low': 'Max Stock Low',
  'label.machineTypeStock.maxStockOut.medium': 'Max Stock Medium',
  'label.machineTypeStock.maxStockOut.high': 'Max Stock High',
  'label.midtrans.active': 'Active',
  'label.midtrans.clientKey': 'Client Key',
  'label.midtrans.description': 'Description',
  'label.midtrans.id': 'ID',
  'label.midtrans.isProduction': 'Is Production?',
  'label.midtrans.merchantId': 'Merchant ID',
  'label.midtrans.serverKey': 'Server Key',
  'label.order.cluster': 'Cluster',
  'label.order.cogs': 'Cost of Goods Sold',
  'label.order.corporateDiscount': 'Corporate Discount',
  'label.order.createdTime': 'Created Time',
  'label.order.duration': 'Duration',
  'label.order.gmv': 'GMV',
  'label.order.hubName': 'Location Name',
  'label.order.machineNo': 'Machine No',
  'label.order.orderNo': 'Order No',
  'label.order.originalPrice': 'Original Price',
  'label.order.paidTime': 'Paid Time',
  'label.order.payDoneRTime': 'Pay Done R Time',
  'label.order.paymentMethod': 'Payment Method',
  'label.order.paymentReferenceId': 'Payment Ref No',
  'label.order.price': 'Price',
  'label.order.productDoneTime': 'Product Done Time',
  'label.order.productName': 'Product',
  'label.order.remark': 'Remark',
  'label.order.salesType': 'Sales Type',
  'label.order.state': 'State',
  'label.order.subsidy': 'Subsidy',
  'label.order.thirdPartyApp': 'Third Party App',
  'label.packaging.active': 'Active',
  'label.packaging.description': 'Description',
  'label.packaging.name': 'Name',
  'label.packaging.quantity': 'Quantity',
  'label.paymentProvider.activateAll': 'Activate All',
  'label.paymentProvider.deactivateAll': 'Deactivate All',
  'label.product.active': 'Active',
  'label.product.amount': 'Amount',
  'label.product.cogs': 'Cost of Goods Sold',
  'label.product.containCoffee': 'Contain coffee?',
  'label.product.cookingDuration': 'Cooking Duration',
  'label.product.duration': 'Duration',
  'label.product.durationInSeconds': 'Duration (in Seconds)',
  'label.product.id': 'ID',
  'label.product.id.optional': 'ID (optional)',
  'label.product.ingredient': 'Ingredient',
  'label.product.weight': 'Weight (in Gram)',
  'label.product.maxDuration': 'Maximum cooking duration (in second)',
  'label.product.minDuration': 'Minimum cooking duration (in second)',
  'label.product.name': 'Name',
  'label.product.type': 'Type',
  'label.product.updatedTime': 'Updated Time',
  'label.productDetail.active': 'Active',
  'label.productDetail.amount': 'Amount',
  'label.productDetail.fastcode': 'Fast Code',
  'label.productDetail.id': 'ID',
  'label.productDetail.ingredient': 'Ingredient',
  'label.productDetail.name': 'Name',
  'label.productDetail.product': 'Product',
  'label.productSet.active': 'Active',
  'label.productSet.id': 'ID',
  'label.productSet.name': 'Name',
  'label.productSet.product': 'Menu',
  'label.qrTypeMapping.activatePaymentProvider': 'Activate Payment Provider',
  'label.qrTypeMapping.inactivatePaymentProvider': 'Inactivate Payment Provider',
  'label.qrTypeMapping.paymentProvider': 'PaymentProvider',
  'label.refillerSchedule.carRefiller': 'Car Refiller',
  'label.refillerSchedule.friday': 'Friday',
  'label.refillerSchedule.monday': 'Monday',
  'label.refillerSchedule.refiller': 'Refiller',
  'label.refillerSchedule.maxDistanceRefiller': 'Refiller Max Distance',
  'label.refillerSchedule.refillerType': 'Refiller Type',
  'label.refillerSchedule.carNumber': 'Car Number',
  'label.refillerSchedule.saturday': 'Saturday',
  'label.refillerSchedule.sunday': 'Sunday',
  'label.refillerSchedule.thursday': 'Thursday',
  'label.refillerSchedule.tuesday': 'Tuesday',
  'label.refillerSchedule.user': 'User',
  'label.refillerSchedule.wednesday': 'Wednesday',
  'label.refillerSchedule.weeklySchedule': 'Weekly Schedule',
  'label.refillerSchedule.from': 'FROM',
  'label.refillerSchedule.to': 'TO',
  'label.rewardRule.amount': 'Amount Limit per Person',
  'label.rewardRule.usage': 'Frequency Limit per Person',
  'label.role.active': 'Active',
  'label.role.authorities': 'Authorities',
  'label.role.description': 'Description',
  'label.role.name': 'Name',
  'label.shop.aboutUs.content': 'Content',
  'label.shop.courier.courier': 'User',
  'label.shop.courier.operational': 'Schedule',
  'label.shop.courier.operational.end': 'End',
  'label.shop.courier.operational.hub': 'Outlet',
  'label.shop.courier.operational.start': 'Start',
  'label.shop.customer.birthdate': 'Date of Birth',
  'label.shop.customer.dateCreated': 'Registration date',
  'label.shop.customer.email': 'Email',
  'label.shop.customer.gender': 'Gender',
  'label.shop.customer.id': 'Id',
  'label.shop.customer.membershipLevel': 'Membership Level',
  'label.shop.customer.name': 'Name',
  'label.shop.customer.phoneNo': 'Phone no.',
  'label.shop.customer.referralCode': 'Referral Code',
  'label.shop.customer.referralCount': 'Referral Count',
  'label.shop.customer.referredBy': 'Referred By',
  'label.shop.customer.status': 'Status',
  'label.shop.customerAddress.address1': 'Address',
  'label.shop.customerAddress.address2': 'Address Detail',
  'label.shop.customerAddress.city': 'City',
  'label.shop.customerAddress.contactPersonName': 'Contact Person',
  'label.shop.customerAddress.contactPersonPhoneNo': 'Phone Number',
  'label.shop.customerAddress.latitude': 'Latitude',
  'label.shop.customerAddress.longitude': 'Longitude',
  'label.shop.customerAddress.name': 'Address Name',
  'label.shop.customerAddress.note': 'Note',
  'label.shop.deal.description': 'Description',
  'label.shop.deal.endDate': 'End Date',
  'label.shop.deal.impression': 'Total impression',
  'label.shop.deal.name': 'Name',
  'label.shop.deal.partner': 'Partner',
  'label.shop.deal.pathName': 'Page Link',
  'label.shop.deal.qrImage': 'QR Image',
  'label.shop.deal.qrString': 'QR String',
  'label.shop.deal.scanned': 'Total scanned',
  'label.shop.deal.startDate': 'Start Date',
  'label.shop.deal.status': 'Status',
  'label.shop.deal.thirdPartyPromo': 'Banner',
  'label.shop.deal.voucher': 'Voucher',
  'label.shop.deliveryConfiguration.deliveryFeePerKm': 'Delivery Fee Per Km',
  'label.shop.deliveryConfiguration.deliveryFlatFee': 'Delivery Flat Fee',
  'label.shop.deliveryConfiguration.maximumFlatFeeDeliveryDistanceInKm': 'Max Flat Fee Delivery Distance in Km',
  'label.shop.faq.description': 'Answer',
  'label.shop.faq.title': 'Question',
  'label.shop.faq.type': 'Type',
  'label.shop.homeAnnouncement.description': 'Announcement',
  'label.shop.homeBackground.imageFile': 'Image File',
  'label.shop.homeBackground.imageUrl': 'Image URL',
  'label.shop.hub.address': 'Address',
  'label.shop.hub.city': 'City',
  'label.shop.hub.close': 'Close',
  'label.shop.hub.cluster': 'Cluster',
  'label.shop.hub.dateCreated': 'Date Created',
  'label.shop.hub.id': 'Id',
  'label.shop.hub.latitude': 'Latitude',
  'label.shop.hub.longitude': 'Longitude',
  'label.shop.hub.name': 'Name',
  'label.shop.hub.no': 'Outlet ID',
  'label.shop.hub.open': 'Open',
  'label.shop.hub.operationals': 'Outlet Operationals',
  'label.shop.hub.phoneNo': 'Phone No.',
  'label.shop.hub.postalCode': 'Postal Code',
  'label.shop.hub.status': 'Status',
  'label.shop.hubMenu.name': 'Name',
  'label.shop.hubMenu.description': 'Description',
  'label.shop.hubMenu.price': 'Price',
  'label.shop.hubMenu.discountedPrice': 'Discounted Price',
  'label.shop.hubMenu.discountStartAt': 'Start Date',
  'label.shop.hubMenu.discountEndAt': 'End Date',
  'label.shop.hubMenu.': 'Min Stock',
  'label.shop.hub.targetItem': 'Target Item',
  'label.shop.hub.targetRevenue': 'Target Revenue',
  'label.shop.hub.targetOrder': 'Target Order',
  'label.shop.hubStock.currentStock': 'Current Stock',
  'label.shop.hubStock.ingredient': 'Ingredient',
  'label.shop.hubStock.maxStock': 'Max Stock',
  'label.shop.hubStock.minStock': 'Min Stock',
  'label.shop.ingredient.name': 'Name',
  'label.shop.ingredient.packagingQuantity': 'Packaging Quantity',
  'label.shop.ingredient.remark': 'Remark',
  'label.shop.ingredient.unit': 'Unit',
  'label.shop.membership.color': 'Icon Color',
  'label.shop.membership.isActive': 'Status',
  'label.shop.membership.isVisible': 'Display on PWA',
  'label.shop.membership.image': 'Image',
  'label.shop.membership.imageUrl': 'Image URL',
  'label.shop.membership.isPrivilegeVisible': 'Privilege Visibility',
  'label.shop.membership.levelName': 'Level Name',
  'label.shop.membership.obtainQualification': 'Obtain Qualification',
  'label.shop.membership.priority': 'Priority',
  'label.shop.membership.privilege': 'Privilege',
  'label.shop.membership.qualificationDuration': 'Qualification Duration (in Days)',
  'label.shop.membership.retainQualification': 'Retain Qualification',
  'label.shop.membershipPromo.description': 'Description',
  'label.shop.membershipPromo.endAt': 'End Period',
  'label.shop.membershipPromo.isActive': 'Status',
  'label.shop.membershipPromo.membershipId': 'Level',
  'label.shop.membershipPromo.referralName': 'Referral Name',
  'label.shop.membershipPromo.startAt': 'Start Period',
  'label.shop.membershipPromo.totalUsed': 'Total Used',
  'label.shop.menu.additionalCogs': 'Additional COGS',
  'label.shop.menu.additionalPrice': 'Additional Price',
  'label.shop.menu.amount': 'Amount',
  'label.shop.menu.cogs': 'COGS',
  'label.shop.menu.createdAt': 'Created At',
  'label.shop.menu.description': 'Description',
  'label.shop.menu.detailImage': 'Detail Menu Image URL',
  'label.shop.menu.detailImageFile': 'Detail Menu Image',
  'label.shop.menu.id': 'ID',
  'label.shop.menu.image': 'Menu Image URL',
  'label.shop.menu.imageFile': 'Menu Image',
  'label.shop.menu.ingredient': 'Ingredient',
  'label.shop.menu.isImageDifferentWithDetailImage': 'Different Image Between Menu and Menu Detail',
  'label.shop.menu.menuCategory': 'Category',
  'label.shop.menu.menuCustomizationItem': 'Customization',
  'label.shop.menu.menuSubcategory': 'Subcategory',
  'label.shop.menu.menuDisplayCategory': 'Display Category',
  'label.shop.menu.merchant': 'Merchant',
  'label.shop.menu.name': 'Name',
  'label.shop.menu.price': 'Price',
  'label.shop.menu.productId': 'Product Id',
  'label.shop.menu.status': 'Status',
  'label.shop.menu.totalItem': 'Total Item',
  'label.shop.menu.uploadImage': 'Image',
  'label.shop.menu.unit': 'Unit',
  'label.shop.menu.weight': 'Weight',
  'label.shop.menuCategory.active': 'Active',
  'label.shop.menuCategory.description': 'Description',
  'label.shop.menuCategory.id': 'ID',
  'label.shop.menuCategory.name': 'Name',
  'label.shop.menuDisplayCategory.description': 'Description',
  'label.shop.menuDisplayCategory.id': 'ID',
  'label.shop.menuDisplayCategory.name': 'Name',
  'label.shop.menuDisplayCategory.status': 'Status',
  'label.shop.menuSubcategory.status': 'Status',
  'label.shop.menuSubcategory.id': 'ID',
  'label.shop.menuSubcategory.menuCategory': 'Category',
  'label.shop.menuSubcategory.name': 'Name',
  'label.shop.menu.variant': 'Variant',
  'label.shop.menu.toppings': 'Toppings',
  'label.shop.merchant.dateCreated': 'Created date',
  'label.shop.merchant.id': 'Id',
  'label.shop.merchant.isJumpstartMerchant': 'Jumpstart Merchant?',
  'label.shop.merchant.name': 'Name',
  'label.shop.merchant.origin': 'Origin',
  'label.shop.merchant.status': 'Status',
  'label.shop.order.acceptedTime': 'Accepted Time',
  'label.shop.order.cluster': 'Cluster',
  'label.shop.order.collectedBy': 'Collected By',
  'label.shop.order.createdBy': 'Created By',
  'label.shop.order.completedTime': 'Completed Time',
  'label.shop.order.courierVendor': 'Courier Vendor',
  'label.shop.order.createdTime': 'Created Time',
  'label.shop.order.customerAddress': 'Customer Address',
  'label.shop.order.customerAddressDetail': 'Customer Address Detail',
  'label.shop.order.customerId': 'Customer ID',
  'label.shop.order.customerName': 'Customer Name',
  'label.shop.order.customerPhoneNo': 'Customer Phone No',
  'label.shop.order.deliveredTime': 'Delivered Time',
  'label.shop.order.deliveryDiscount': 'Delivery Discount',
  'label.shop.order.deliveryFee': 'Delivery Fee',
  'label.shop.order.deliveryId': 'Delivery ID',
  'label.shop.order.deliveryPrice': 'Delivery Price',
  'label.shop.order.deliveryStatus': 'Delivery Status',
  'label.shop.order.deliveryType': 'Delivery Type',
  'label.shop.order.discountAmount': 'Discount',
  'label.shop.order.distance': 'Distance',
  'label.shop.order.driverId': 'Driver ID',
  'label.shop.order.driverName': 'Driver Name',
  'label.shop.order.driverPhone': 'Driver Phone',
  'label.shop.order.expectedCompletedTime': 'Scheduled Time',
  'label.shop.order.vendorOrderNo': '{vendorOrderNo}',
  'label.shop.order.vendorPrice': 'Vendor Price',
  'label.shop.order.hub': 'Outlet',
  'label.shop.order.items': 'Item(s)',
  'label.shop.order.latitude': 'Latitude',
  'label.shop.order.liveTrackingUrl': 'Tracking Link',
  'label.shop.order.longitude': 'Longitude',
  'label.shop.order.membershipLevel': 'Membership Level',
  'label.shop.order.orderId': 'Order ID',
  'label.shop.order.orderNo': 'Order No',
  'label.shop.order.orderNoAlias': 'Order No Alias',
  'label.shop.order.paidAmount': 'Total Paid',
  'label.shop.order.paidTime': 'Paid Time',
  'label.shop.order.paymentMethod': 'Payment Method',
  'label.shop.order.paymentNotes': 'Payment Notes',
  'label.shop.order.paymentReferenceId': 'Payment Reff',
  'label.shop.order.pickUpTime': 'Pick Up Time',
  'label.shop.order.remark': 'Remark',
  'label.shop.order.salesType': 'Sales Type',
  'label.shop.order.scheduleType': 'Schedule Type',
  'label.shop.order.state': 'Status',
  'label.shop.order.time': 'Time',
  'label.shop.order.totalPrice': 'Total Price',
  'label.shop.order.type': 'Type',
  'label.shop.order.usedPoints': 'Used Points',
  'label.shop.partner.name': 'Name',
  'label.shop.partner.status': 'Status',
  'label.shop.popupBanner.manual.clickCount': 'Click Count',
  'label.shop.popupBanner.manual.customerType': 'Customer Type',
  'label.shop.popupBanner.manual.endDate': 'End Date',
  'label.shop.popupBanner.manual.id': 'Id',
  'label.shop.popupBanner.manual.image': 'Image',
  'label.shop.popupBanner.manual.imageUrl': 'Image Url',
  'label.shop.popupBanner.manual.name': 'Name',
  'label.shop.popupBanner.manual.startDate': 'Start Date',
  'label.shop.popupBanner.manual.url': 'Url',
  'label.shop.pos.cart.customer': 'Customer',
  'label.shop.pos.cart.customerName': 'Name',
  'label.shop.pos.cart.payment.notes': 'Notes',
  'label.shop.pos.cart.paymentMethod': 'Payment Method',
  'label.shop.pos.cart.promoPartner': 'Promo Partner',
  'label.shop.pos.cart.salesType': 'Sales Type',
  'label.shop.pos.menu.amount': 'Amount',
  'label.shop.pos.menu.notes': 'Notes',
  'label.shop.posPromo.allowedOrderType': 'Allowed Order Type',
  'label.shop.posPromo.allowedPaymentMethod': 'Allowed Payment Method',
  'label.shop.posPromo.appliedToAddons': 'Applied to Addons',
  'label.shop.posPromo.appliedToVariant': 'Applied to Variant',
  'label.shop.posPromo.appliedToVariantTopping': 'Applied to Variant / Topping',
  'label.shop.posPromo.appliedToTopping': 'Applied to Topping',
  'label.shop.posPromo.client.lastOrder': "Client's last order",
  'label.shop.posPromo.client.numberOfOrders': "Client's with a certain number of orders",
  'label.shop.posPromo.client.totalOrderAmount': "Client's with total order amount",
  'label.shop.posPromo.close': 'Close',
  'label.shop.posPromo.createdDate': 'Registration date',
  'label.shop.posPromo.customerEndDate': 'Customer End Date',
  'label.shop.posPromo.customerIds': 'Customer Id(s)',
  'label.shop.posPromo.customerLastOrder': 'Customer Last Order',
  'label.shop.posPromo.customerLastOrderUnit': 'Customer Last Order Unit',
  'label.shop.posPromo.customerNumberOfOrders': 'Customer Number of Orders',
  'label.shop.posPromo.customerStartDate': 'Customer Start Date',
  'label.shop.posPromo.customerTotalAmount': 'Customer Total Amount',
  'label.shop.posPromo.customerType': 'Customer Type',
  'label.shop.posPromo.dateAvailability': 'Date Availability',
  'label.shop.posPromo.description': 'Description',
  'label.shop.posPromo.discountAmount': 'Discount Amount',
  'label.shop.posPromo.discountAmountUnit': 'Discount Amount Unit',
  'label.shop.posPromo.endDate': 'End Date',
  'label.shop.posPromo.hubs': 'Outlets',
  'label.shop.posPromo.idr': 'IDR',
  'label.shop.posPromo.isLoyaltyPoint': 'Apply as Loyalty Point',
  'label.shop.posPromo.item': 'item',
  'label.shop.posPromo.itemRule': 'Item Rule',
  'label.shop.posPromo.maximumDiscountAmount': 'Maximum Discount Amount',
  'label.shop.posPromo.membershipIds': 'Membership Level',
  'label.shop.posPromo.minimumAmount': 'Minimum Amount',
  'label.shop.posPromo.minimumItem': 'Minimum Item',
  'label.shop.posPromo.name': 'Name',
  'label.shop.posPromo.open': 'Open',
  'label.shop.posPromo.partner': 'Allowed Partner',
  'label.shop.posPromo.perCustomer': 'per Customer',
  'label.shop.posPromo.schedule': 'Schedule',
  'label.shop.posPromo.specificCustomer': 'Specific Customer',
  'label.shop.posPromo.specificMembership': 'Specific Membership',
  'label.shop.posPromo.startDate': 'Start Date',
  'label.shop.posPromo.title': 'Title',
  'label.shop.posPromo.to': 'to',
  'label.shop.posPromo.totalQuantity': 'Total Quantity',
  'label.shop.posPromo.usageLimitPerCustomer': 'Limit per Customer',
  'label.shop.posPromoPartner.name': 'Name',
  'label.shop.posPromoPartner.status': 'Status',
  'label.shop.pwaConfiguration.deliveryEndTime': 'Closing Operational Hours for Delivery Order',
  'label.shop.pwaConfiguration.deliveryStartTime': 'Opening Operational Hours for Delivery Order',
  'label.shop.pwaConfiguration.deliveryTimeSelectionRangeInMinute':
    'Delivery Range Time for Time Selection on Checkout Page',
  'label.shop.pwaConfiguration.hubGopayAdditionalInfo': 'Outlet GoPay Additional Info',
  'label.shop.pwaConfiguration.hubLinkAjaAdditionalInfo': 'Outlet LinkAja Additional Info',
  'label.shop.pwaConfiguration.hubShopeePayAdditionalInfo': 'Outlet ShopeePay Additional Info',
  'label.shop.pwaConfiguration.machineGopayAdditionalInfo': 'Machine GoPay Additional Info',
  'label.shop.pwaConfiguration.machineLinkAjaAdditionalInfo': 'Machine LinkAja Additional Info',
  'label.shop.pwaConfiguration.machineShopeePayAdditionalInfo': 'Machine ShopeePay Additional Info',
  'label.shop.pwaConfiguration.maximumLoyaltyPointsUsedInPercentage': 'Maximum Loyalty Points Used in Percentage',
  'label.shop.pwaConfiguration.pendingToLiveDurationInMinute': 'Pending Order to Live Order Duration',
  'label.shop.pwaConfiguration.pickUpEndTime': 'Closing Operational Hours for Pick Up Order',
  'label.shop.pwaConfiguration.pickUpStartTime': 'Opening Operational Hours for Pick Up Order',
  'label.shop.pwaConfiguration.pickUpTimeSelectionRangeInMinute':
    'Pick Up Range Time for Time Selection on Checkout Page',
  'label.shop.pwaConfiguration.popupBannerDelayTimeInSeconds': 'Popup Banner Delay Time in Seconds',
  'label.shop.task.address': 'Address',
  'label.shop.task.shopHub': 'Shop Hub',
  'label.shop.tnc.description': 'TnC Description',
  'label.shop.tnc.title': 'TnC Title',
  'label.shop.topping.cogs': 'COGS',
  'label.shop.topping.name': 'Name',
  'label.shop.topping.price': 'Price',
  'label.shop.topping.productId': 'Product ID',
  'label.shop.topping.status': 'Status',
  'label.shop.variant.name': 'Name',
  'label.shop.variant.productIdCombinedInShopOrderMenu': 'Product Id Combined In Order Menu',
  'label.shop.variant.status': 'Status',
  'label.shopDiscount.allCustomers': 'All Customers (Registered + Guest)',
  'label.shopDiscount.allowedOrderType': 'Allowed Order Type',
  'label.shopDiscount.appliedToAddons': 'Applied to Addons',
  'label.shopDiscount.appliedToVariant': 'Applied to Variant',
  'label.shopDiscount.appliedToVariantTopping': 'Applied to Variant / Topping',
  'label.shopDiscount.appliedToTopping': 'Applied to Topping',
  'label.shopDiscount.client.lastOrder': "Client's last order",
  'label.shopDiscount.client.lastOrder.detail': "Client's last order: {numberOfUnit} {unit}",
  'label.shopDiscount.client.numberOfOrders': "Client's with a certain number of orders",
  'label.shopDiscount.client.numberOfOrders.detail':
    "Client's with a certain number of orders: {numberOfOrders} orders",
  'label.shopDiscount.client.totalOrderAmount': "Client's with total order amount",
  'label.shopDiscount.client.totalOrderAmount.detail': "Client's with total order amount: {totalOrderAmount} IDR",
  'label.shopDiscount.close': 'Close',
  'label.shopDiscount.createdDate.detail': 'Registration date: {createdDate}',
  'label.shopDiscount.customerEndDate': 'Customer End Date',
  'label.shopDiscount.customerIds': 'Customer Id(s)',
  'label.shopDiscount.customerLastOrder': 'Customer Last Order',
  'label.shopDiscount.customerLastOrderUnit': 'Customer Last Order Unit',
  'label.shopDiscount.customerNumberOfOrders': 'Customer Number of Orders',
  'label.shopDiscount.customerStartDate': 'Customer Start Date',
  'label.shopDiscount.customerTotalAmount': 'Customer Total Amount',
  'label.shopDiscount.customerType': 'Customer Type',
  'label.shopDiscount.dateAvailability': 'Date Availability',
  'label.shopDiscount.createdDate': 'Registration date',
  'label.shopDiscount.defaultDateAvailability': 'Unlimited (Default)',
  'label.shopDiscount.defaultDuration': 'All time',
  'label.shopDiscount.defaultSchedules': '24/7 (Default)',
  'label.shopDiscount.description': 'Description',
  'label.shopDiscount.discountAmount': 'Discount Amount',
  'label.shopDiscount.discountAmountUnit': 'Discount Amount Unit',
  'label.shopDiscount.discountLevel': 'Discount Level',
  'label.shopDiscount.endDate': 'End Date',
  'label.shopDiscount.idr': 'IDR',
  'label.shopDiscount.image': 'Image',
  'label.shopDiscount.imageUrl': 'Image URL',
  'label.shopDiscount.isAssignedToSpecificUserId': 'Assign to Specific User ID',
  'label.shopDiscount.isBulk': 'Bulk with Different Voucher Code',
  'label.shopDiscount.isDefaultQrPromo': 'Default QR Promo',
  'label.shopDiscount.isLoyaltyPoint': 'Apply as Loyalty Point',
  'label.shopDiscount.isVisible': 'Voucher Visibility',
  'label.shopDiscount.item': 'item',
  'label.shopDiscount.itemRule': 'Item Rule',
  'label.shopDiscount.maximumDiscountAmount': 'Maximum Discount Amount',
  'label.shopDiscount.maximumDiscountAmountPerTransaction': 'Maximum Discount Amount per Transaction',
  'label.shopDiscount.maximumDiscountAmountPerTransaction.detail':
    'Maximum Discount Amount per Transaction: Rp{shopProductDiscountMaximumAmountPerTransaction}',
  'label.shopDiscount.maximumDiscountAmountPerTransaction.editAmount': 'Edit Amount',
  'label.shopDiscount.membershipIds': 'Membership Level',
  'label.shopDiscount.membershipIds.detail': 'Membership Level: {membershipIds}',
  'label.shopDiscount.minimumAmount': 'Minimum Amount',
  'label.shopDiscount.minimumItem': 'Minimum Item',
  'label.shopDiscount.name': 'Name',
  'label.shopDiscount.numberOfVoucherCode': 'Number of Voucher',
  'label.shopDiscount.open': 'Open',
  'label.shopDiscount.orders': 'orders',
  'label.shopDiscount.perCustomer': 'per customer',
  'label.shopDiscount.perVoucherCode': 'per voucher code',
  'label.shopDiscount.qrPromo': 'Show on promo page',
  'label.shopDiscount.quantityPerCustomer': 'Quantity per Customer',
  'label.shopDiscount.quantityPerVoucherCode': 'Quantity per Voucher Code',
  'label.shopDiscount.registeredCustomers': 'Registered Customers',
  'label.shopDiscount.schedule': 'Schedule',
  'label.shopDiscount.schedules': 'Schedules',
  'label.shopDiscount.specificCustomer': 'Specific Customer',
  'label.shopDiscount.specificCustomers': 'Specific Customer IDs',
  'label.shopDiscount.specificCustomers.detail': 'Specific Customer IDs: {customerIds}',
  'label.shopDiscount.specificMembership': 'Specific Membership',
  'label.shopDiscount.specificMemberships': 'Specific Membership IDs',
  'label.shopDiscount.specificMemberships.detail': 'Specific Membership IDs: {membershipIds}',
  'label.shopDiscount.startDate': 'Start Date',
  'label.shopDiscount.title': 'Title',
  'label.shopDiscount.to': 'to',
  'label.shopDiscount.totalQuantity': 'Total Quantity',
  'label.shopDiscount.usageLimitPerCustomer': 'Limit per Customer',
  'label.shopDiscount.voucherCode': 'Voucher Code',
  'label.shopDiscount.voucherCodePrefix': 'Voucher Code Prefix',
  'label.shopDiscount.voucherDuration': 'Voucher Duration (days)',
  'label.shopDiscount.voucherQuantityOption': 'Voucher Quantity',
  'label.shopDiscount.voucherTrigger': 'Voucher Trigger',
  'label.shopDiscount.voucherType': 'Voucher Type',
  'label.shopMenuCustomization.active': 'Active',
  'label.shopMenuCustomization.id': 'ID',
  'label.shopMenuCustomization.id.optional': 'ID (optional)',
  'label.shopMenuCustomization.item': 'Item',
  'label.shopMenuCustomization.item.name': 'Item',
  'label.shopMenuCustomization.name': 'Name',
  'label.shopNotification.active': 'Active',
  'label.shopNotification.customerEndDate': 'End Date',
  'label.shopNotification.customerIds': 'Customer Ids',
  'label.shopNotification.customerLastOrder': "Client's last order",
  'label.shopNotification.customerNumberOfOrders': "Client's with a certain number of orders",
  'label.shopNotification.customerRegistrationDate': 'Registration Date',
  'label.shopNotification.customerStartDate': 'Start Date',
  'label.shopNotification.customerTotalOrderAmount': "Client's total order amount",
  'label.shopNotification.customerType': 'Customer Type',
  'label.shopNotification.descriptionPreview': 'Description Preview',
  'label.shopNotification.detail.buttonName': 'Button Name',
  'label.shopNotification.detail.buttonUrl': 'Button URL',
  'label.shopNotification.detail.description': 'Description',
  'label.shopNotification.detail.image': 'Image',
  'label.shopNotification.detail.imageUrl': 'Image URL',
  'label.shopNotification.detail.showButton': 'Show Button',
  'label.shopNotification.detailUrl': 'URL',
  'label.shopNotification.gender': 'Gender',
  'label.shopNotification.icon': 'Icon',
  'label.shopNotification.iconUrl': 'Icon URL',
  'label.shopNotification.imageUrl': 'Image URL',
  'label.shopNotification.isPushNotificationChannel': 'Push Notification',
  'label.shopNotification.membershipIds': 'Membership Ids',
  'label.shopNotification.name': 'Name',
  'label.shopNotification.pushNotification': 'Push Notification',
  'label.shopNotification.sentDate': 'Date',
  'label.shopNotification.showDetailPage': 'Show detail page',
  'label.shopNotification.specificCustomers': 'Specific Customers',
  'label.shopNotification.specificMembership': 'Membership Level',
  'label.shopNotification.title': 'Title',
  'label.shopNotification.to': 'to',
  'label.shopNotification.url': 'URL',
  'label.shopScrollingBanner.clickCount': 'Click Count',
  'label.shopScrollingBanner.dateTimeEnd': 'Date/time end',
  'label.shopScrollingBanner.dateTimeStart': 'Date/time start',
  'label.shopScrollingBanner.id': 'ID',
  'label.shopScrollingBanner.image': 'Upload image',
  'label.shopScrollingBanner.imageUrl': 'Image URL',
  'label.shopScrollingBanner.name': 'Banner name',
  'label.shopScrollingBanner.priority': 'Priority',
  'label.shopScrollingBanner.status': 'Status',
  'label.shopScrollingBanner.url': 'Click URL',
  'label.shopHubMenu': 'Menu',
  'label.shopThirdPartyPromo.click': 'Click',
  'label.shopThirdPartyPromo.endDate': 'End Date',
  'label.shopThirdPartyPromo.image': 'Image',
  'label.shopThirdPartyPromo.impression': 'Impression',
  'label.shopThirdPartyPromo.name': 'Name',
  'label.shopThirdPartyPromo.startDate': 'Start Date',
  'label.shopThirdPartyPromo.url': 'URL',
  'label.stockPreparationDetail.carRefillerName': 'Name',
  'label.stockPreparationDetail.carNumber': 'Car Number',
  'label.sparePart.name': 'Name',
  'label.sparePart.description': 'Description',
  'label.task.assignee': 'Assigned to',
  'label.task.assigner': 'Assigned by',
  'label.task.createdTime': 'Created Time',
  'label.task.description': 'Description',
  'label.task.executionTime': 'Execution Time',
  'label.task.finishTime': 'Finish Time',
  'label.task.image': 'Image 1',
  'label.task.machine': 'Machine',
  'label.task.note': 'Note',
  'label.task.note.decline': 'Decline reason',
  'label.task.optionalImage': 'Image 2',
  'label.task.startedTime': 'Started Time',
  'label.task.state': 'State',
  'label.task.title': 'Title',
  'label.task.type': 'Type',
  'label.task.updatedTime': 'Updated Time',
  'label.taskReport.name': 'Name',
  'label.taskReport.carNumber': 'Car Number',
  'label.taskReport.executionDay': 'Execution Day',
  'label.taskReport.numberOfTaskFinished': 'No of Task Finished',
  'label.taskReport.firstStartTime': 'Fist Start Time',
  'label.taskReport.lastEndTime': 'Last End Time',
  'label.taskReport.finishReconTime': 'Finish Recon Time',
  'label.taskReport.distanceTravelled': 'Distance Travelled',
  'label.taskReport.averageMachineDuration': 'Avg Duration / Machine',
  'label.taskReport.shortestMachineDuration': 'Shortest Machine Duration',
  'label.taskReport.longestMachineDuration': 'Longest MachineDuration',
  'label.taskReport.state': 'Status',
  'label.taskReport.notes': 'Notes',
  'label.taskReport.product-return': 'Product Return',
  'label.taskReport.table.stock-out-decrement': 'Stock Out Can`t be Refilled',
  'label.taskReport.table.stock-out-return': 'Stock Out Taken Out from Machine',
  'label.taskReport.table.total': 'Total',
  'label.taskReport.table.machine-no': 'Machine No.',
  'label.taskReport.table.coffee-machine': 'Coffee Machine',
  'label.taskReport.table.snack-machine': 'Snack Machine',
  'label.topping': 'Topping',
  'label.unit.description': 'Description',
  'label.unit.name': 'Name',
  'label.user.active': 'Active',
  'label.user.carRefiller': 'Car Refiller',
  'label.user.confirmPassword': 'Confirm Password',
  'label.user.email': 'Email',
  'label.user.id': 'ID',
  'label.user.latitude': 'Latitude',
  'label.user.longitude': 'Longitude',
  'label.user.manager': 'Manager',
  'label.user.name': 'Name',
  'label.user.password': 'Password',
  'label.user.phoneNo': 'Phone Number',
  'label.user.refiller': 'Refiller',
  'label.user.role': 'Role',
  'label.user.username': 'Username',
  'label.user.weeklySchedule': 'Weekly Schedule',
  'label.variant': 'Variant',

  'link.cardCode': 'Card Code',
  'link.cimbDashboardFailRequest': 'CIMB Dashboard Fail Request',
  'link.cluster': 'Cluster',
  'link.clusterAdd': 'Add Cluster',
  'link.clusterEdit': 'Edit Cluster',
  'link.company': 'Company',
  'link.companyAdd': 'Add Company',
  'link.companyDetail': 'Company Detail',
  'link.companyEdit': 'Edit Company',
  'link.concisedOrder': 'Order Concised',
  'link.concisedOrderDetail': 'Order Concised Detail',
  'link.dashboard': 'Dashboard',
  'link.department': 'Department',
  'link.departmentAdd': 'Add Department',
  'link.departmentEdit': 'Edit Department',
  'link.dynamicsFailRequest': 'Dynamics Fail Request',
  'link.hub': 'Location',
  'link.hubAdd': 'Add Location',
  'link.hubDetail': 'Location Detail',
  'link.hubEdit': 'Edit Location',
  'link.hubType': 'Location Type',
  'link.hubTypeAdd': 'Add Location Type',
  'link.hubTypeDetail': 'Location Type Detail',
  'link.hubTypeEdit': 'Edit Location Type',
  'link.ingredient': 'Ingredient',
  'link.ingredientAdd': 'Add Ingredient',
  'link.ingredientEdit': 'Edit Ingredient',
  'link.latteConfig': 'AI Configuration',
  'link.latteConfigDetail': 'AI Configuration',
  'link.latteConfigEdit': 'Edit AI Configuration',
  'link.leave': 'Leave',
  'link.leaveAdd': 'Add Leave',
  'link.leaveDetail': 'Leave Detail',
  'link.leaveEdit': 'Edit Leave',
  'link.machine': 'Machine',
  'link.machineDraft': 'Machine Draft',
  'link.machineDraftConvertToMachine': 'Convert Machine Draft to Machine',
  'link.machineHubAdd': 'Set Machine',
  'link.machineHubEdit': 'Edit Machine',
  'link.machineHubUnload': 'Unload Machine',
  'link.machine.menu.edit': 'Edit Machine Menu',
  'link.machine.menu.history': 'Pricing History',
  'link.machineAdd': 'Add Machine',
  'link.machineDetail': 'Machine Detail',
  'link.machineEdit': 'Edit Machine',
  'link.machineError': 'Machine Error',
  'link.machineErrorAdd': 'Add Machine Error',
  'link.machineErrorDetail': 'Machine Error Detail',
  'link.machineErrorEdit': 'Edit Machine Error',
  'link.machinePayment': 'Machine Payment',
  'link.machinePayment.add': 'Add Machine Payment',
  'link.machinePayment.detail': 'Machine Payment Detail',
  'link.machineQr': 'Qr Code',
  'link.machineQrAdd': 'Add Qr Code',
  'link.machineQrEdit': 'Edit Qr Code',
  'link.machineStock.fillIn': 'Fill Stock In',
  'link.machineStock.fillOut': 'Fill Stock Out',
  'link.machineStockInAdd': 'Add Stock In',
  'link.machineStockInDetail': 'Stock In Detail',
  'link.machineStockInEdit': 'Edit Stock In',
  'link.machineStockOutAdd': 'Add Stock Out',
  'link.machineStockOutEdit': 'Edit Stock Out',
  'link.machineType': 'Machine Type',
  'link.machineTypeAdd': 'Add Machine Type',
  'link.machineTypeDetail': 'Machine Type Detail',
  'link.machineTypeEdit': 'Edit Type Machine',
  'link.MachineTypeStockLevelCupEdit': 'Edit Stock Level Cup',
  'link.machineTypeStockOutEdit': 'Edit Machine Type Stock Out',
  'link.machineTypeStockInEdit': 'Edit Machine Type Stock In',
  'link.machineTypeDisplay': 'Machine Type Display',
  'link.machineTypeDisplay.add': 'Add Machine Type Display',
  'link.machineTypeDisplay.detail': 'Machine Type Display Detail',
  'link.machineTypeDisplay.edit': 'Edit Machine Type Display',
  'link.midtrans': 'Midtrans Account',
  'link.midtransAdd': 'Add Midtrans Account',
  'link.midtransDetail': 'Midtrans Account Detail',
  'link.midtransEdit': 'Edit Midtrans Account',
  'link.order': 'Order',
  'link.orderDetail': 'Order Detail',
  'link.orderLog': 'Order Log',
  'link.orderSummary': 'Order Summary',
  'link.packaging': 'Packaging',
  'link.packaging.add': 'Add Packaging',
  'link.packaging.detail': 'Packaging Detail',
  'link.packaging.edit': 'Edit Packaging',
  'link.paymentProvider': 'Payment Provider',
  'link.product': 'Menu',
  'link.product.add': 'Add Menu',
  'link.product.detail': 'Menu Detail',
  'link.product.edit': 'Edit Menu',
  'link.product.editIngredient': 'Edit Ingredient Menu',
  'link.product.insertIngredient': 'Insert Ingredient',
  'link.productSet': 'Menu Set',
  'link.productSet.add': 'Add Menu Set',
  'link.productSet.detail': 'Menu Set Detail',
  'link.productSet.edit': 'Edit Menu Set',
  'link.productSet.insertProduct': 'Insert Menu',
  'link.profile': 'My Profile',
  'link.profileEdit': 'Edit My Profile',
  'link.profitabilityReport': 'Profitability Report',
  'link.refillerOutOfReach': 'Refiller Out Of Reach',
  'link.refillerSchedule': 'Refiller Schedule',
  'link.refillerSchedule.add': 'Add Refiller Schedule',
  'link.refillerSchedule.edit': 'Edit Refiller Schedule',
  'link.rewardOrder': 'Reward Order',
  'link.rewardRule': 'Rule',
  'link.rewardRule.add': 'Add Rule',
  'link.rewardRule.detail': 'Rule Detail',
  'link.rewardRule.edit': 'Edit Rule',
  'link.role': 'Role',
  'link.roleAdd': 'Add Role',
  'link.roleEdit': 'Edit Role',
  'link.settlementData': 'Settlement Data',
  'link.settlementFile': 'Settlement File',
  'link.shop.aboutUs': 'About Us',
  'link.shop.aboutUs.edit': 'Edit About Us',
  'link.shop.barista': 'Barista',
  'link.shop.barista.liveOrder': 'Live Order',
  'link.shop.barista.liveOrder.title': 'Live Order - {hubNames}',
  'link.shop.courier': 'Courier Schedule',
  'link.shop.courier.add': 'Add Courier Schedule',
  'link.shop.courier.edit': 'Edit Courier Schedule',
  'link.shop.courierOrder': 'Courier Task',
  'link.shop.customer': 'Customer',
  'link.shop.customer.detail': 'Customer Detail',
  'link.shop.customer.edit': 'Edit Customer',
  'link.shop.deal': 'Deals',
  'link.shop.deal.add': 'Add Deals',
  'link.shop.deal.detail': 'View Deals',
  'link.shop.deal.edit': 'Edit Deals',
  'link.shop.deal.pathName.edit': 'Edit Page Link',
  'link.shop.deal.report': 'View Deals Report',
  'link.shop.deal.thirdPartyPromo.add': 'Add',
  'link.shop.deal.voucher.add': 'Add',
  'link.shop.deliveryConfiguration': 'Delivery Configuration',
  'link.shop.deliveryConfiguration.edit': 'Edit Delivery Configuration',
  'link.shop.discount': 'PWA Promotion',
  'link.shop.discount.maximumAmountPerTransaction.edit': 'Edit Maximum Discount Amount per Transaction',
  'link.shop.faq': 'FAQ',
  'link.shop.faq.add': 'Add FAQ',
  'link.shop.faq.edit': 'Edit FAQ',
  'link.shop.homeAnnouncement': 'Home Announcement',
  'link.shop.homeAnnouncement.edit': 'Edit Home Announcement',
  'link.shop.homeBackground': 'Home Background',
  'link.shop.homeBackground.add': 'Add Home Background',
  'link.shop.homeBackground.detail': 'Home Background Detail',
  'link.shop.homeBackground.edit': 'Edit Home Background',
  'link.shop.hub': 'Outlet',
  'link.shop.hub.add': 'Add Outlet',
  'link.shop.hub.detail': 'Outlet Detail',
  'link.shop.hub.edit': 'Edit Outlet',
  'link.shop.hubMenu.edit': 'Edit Menu',
  'link.shop.hubStock.add': 'Add Outlet Stock',
  'link.shop.hubStock.edit': 'Edit Outlet Stock',
  'link.shop.hubStock.fill': 'Fill Stock',
  'link.shop.ingredient': 'Ingredient',
  'link.shop.ingredient.add': 'Add Ingredient',
  'link.shop.ingredient.edit': 'Edit Ingredient',
  'link.shop.loyaltyPointHistory': 'Loyalty Point History',
  'link.shop.membership': 'Membership',
  'link.shop.membership.add': 'Add Membership',
  'link.shop.membership.detail': 'Membership Detail',
  'link.shop.membership.edit': 'Edit Membership',
  'link.shop.membership.qualificationDuration.edit': 'Edit Membership Qualification Duration',
  'link.shop.membershipPromo': 'Membership Promo',
  'link.shop.membershipPromo.detail': 'Membership Promo Detail',
  'link.shop.membershipPromo.edit': 'Edit Membership Promo',
  'link.shop.menu': 'Menu',
  'link.shop.menu.add': 'Add Menu',
  'link.shop.menu.detail': 'View Menu',
  'link.shop.menu.edit': 'Edit Menu',
  'link.shop.menu.editIngredient': 'Edit Ingredient',
  'link.shop.menu.insertIngredient': 'Insert Ingredient',
  'link.shop.menuCategory': 'Menu Category',
  'link.shop.menuCategory.add': 'Add Menu Category',
  'link.shop.menuCategory.detail': 'Menu Category Detail',
  'link.shop.menuCategory.edit': 'Edit Menu Category',
  'link.shop.menuDisplayCategory': 'Menu Display Category',
  'link.shop.menuDisplayCategory.add': 'Add Menu Display Category',
  'link.shop.menuDisplayCategory.detail': 'Menu Display Category Detail',
  'link.shop.menuDisplayCategory.edit': 'Edit Menu Display Category',
  'link.shop.menuSubcategory': 'Menu Subcategory',
  'link.shop.menuSubcategory.add': 'Add Menu Subcategory',
  'link.shop.menuSubcategory.detail': 'Menu Subcategory Detail',
  'link.shop.menuSubcategory.edit': 'Edit Menu Subcategory',
  'link.shop.merchant': 'Merchant',
  'link.shop.merchant.add': 'Add Merchant',
  'link.shop.merchant.detail': 'Merchant Detail',
  'link.shop.merchant.edit': 'Edit Merchant',
  'link.shop.notification': 'Notification',
  'link.shop.order': 'Order',
  'link.shop.order.detail': 'Order Detail',
  'link.shop.order.outletSummary': 'Outlet Summary',
  'link.shop.partner': 'Partner',
  'link.shop.partner.add': 'Add Partner',
  'link.shop.partner.detail': 'Partner Detail',
  'link.shop.partner.edit': 'Edit Partner',
  'link.shop.paymentProvider': 'Shop Payment Provider',
  'link.shop.popupBanner': 'Shop Popup Banner',
  'link.shop.popupBanner.manual.add': 'Add Shop Popup Banner',
  'link.shop.popupBanner.manual.edit': 'Edit Shop Popup Banner',
  'link.shop.popupBanner.manual.detail': 'Shop Popup Banner Detail',
  'link.shop.posPromotion': 'POS Promotion',
  'link.shop.posPromo.add': 'Add POS Promo',
  'link.shop.posPromo.detail': 'POS Promo Detail',
  'link.shop.posPromo.edit': 'Edit POS Promo',
  'link.shop.posPromoPartner.add': 'Add Promo Partner',
  'link.shop.posPromoPartner.detail': 'Promo Partner Detail',
  'link.shop.posPromoPartner.edit': 'Edit Promo Partner',
  'link.shop.product': 'Shop Product',
  'link.shop.product.add': 'Add Shop Product',
  'link.shop.product.detail': 'Shop Product Detail',
  'link.shop.product.discount': 'Promotion',
  'link.shop.product.discount.maximumAmountPerTransaction.edit': 'Edit Maximum Discount Amount per Transaction',
  'link.shop.product.edit': 'Edit Shop Product',
  'link.shop.product.promo.add': 'Add Promo',
  'link.shop.product.promo.detail': 'Promo Detail',
  'link.shop.product.promo.edit': 'Edit Promo',
  'link.shop.product.voucher.add': 'Add Voucher',
  'link.shop.product.voucher.detail': 'Voucher Detail',
  'link.shop.product.voucher.edit': 'Edit Voucher',
  'link.shop.productDisplayCategory': 'Shop Product Display Category',
  'link.shop.productDisplayCategory.add': 'Add Shop Product Display Category',
  'link.shop.productDisplayCategory.detail': 'Shop Product Display Category Detail',
  'link.shop.productDisplayCategory.edit': 'Edit Shop Product Display Category',
  'link.shop.productMachineTypeDisplay': 'Shop Product Machine Type Display',
  'link.shop.productMachineTypeDisplay.add': 'Add Shop Product Machine Type Display',
  'link.shop.productMachineTypeDisplay.edit': 'Edit Shop Product Machine Type Display',
  'link.shop.productOrder': 'Order Card Code',
  'link.shop.productOrder.detail': 'Order Card Code Detail',
  'link.shop.productThirdPartyPromo.add': 'Add Third Party Promo',
  'link.shop.productThirdPartyPromo.detail': 'Third Party Promo Detail',
  'link.shop.productThirdPartyPromo.edit': 'Edit Third Party Promo',
  'link.shop.promo': 'Shop Promo',
  'link.shop.pwaConfiguration': 'PWA Configuration',
  'link.shop.pwaConfiguration.edit': 'Edit PWA Configuration',
  'link.shop.task': 'Outlet Task',
  'link.shop.task.add': 'Add Outlet Task',
  'link.shop.task.assign': 'Assign Outlet Task',
  'link.shop.task.detail': 'Outlet Task Detail',
  'link.shop.task.edit': 'Edit Outlet Task',
  'link.shop.task.me': 'My Outlet Task',
  'link.shop.task.refill': 'Refill Outlet Task',
  'link.shop.thirdPartyPromo.add': 'Add Shop Third Party Promo',
  'link.shop.thirdPartyPromo.detail': 'Shop Third Party Promo Detail',
  'link.shop.thirdPartyPromo.edit': 'Edit Shop Third Party Promo',
  'link.shop.tnc': 'Shop TnC',
  'link.shop.tnc.edit': 'Edit Shop TnC',
  'link.shop.topping': 'Topping',
  'link.shop.topping.add': 'Add Topping',
  'link.shop.topping.edit': 'Edit Topping',
  'link.shop.topping.detail': 'Topping Detail',
  'link.shop.variant': 'Shop Variant',
  'link.shop.variant.add': 'Add Shop Variant',
  'link.shop.variant.detail': 'Shop Variant Detail',
  'link.shop.variant.edit': 'Edit Shop Variant',
  'link.shopAssignedVoucher.add': 'Add Assigned Voucher',
  'link.shopAutomaticVoucher.add': 'Add Automatic Voucher',
  'link.shopAutomaticVoucher.edit': 'Edit Automatic Voucher',
  'link.shopMenu.uploadShopMenu': 'Upload Shop Menu',
  'link.shopMenuCustomization': 'Menu Customization',
  'link.shopMenuCustomization.add': 'Add Menu Customization',
  'link.shopMenuCustomization.detail': 'Menu Customization Detail',
  'link.shopMenuCustomization.edit': 'Edit Menu Customization',
  'link.shopMenuCustomizationItem.addButton': 'Add',
  'link.shopMenuCustomizationItem.deleteButton': 'Delete',
  'link.shopNotification.manual.add': 'Add Manual Notification',
  'link.shopNotification.manual.detail': 'Manual Notification Detail',
  'link.shopNotification.manual.edit': 'Edit Manual Notification',
  'link.shopPosPromoPartner.detail': 'Promo Partner Detail',
  'link.shopPosSalesType': 'Shop Pos Sales Type',
  'link.shopPosSalesType.add': 'Add Shop Pos Sales Type',
  'link.shopPosSalesType.detail': 'Shop Pos Sales Type Detail',
  'link.shopPosSalesType.edit': 'Edit Shop Pos Sales Type',
  'link.shopPromo.add': 'Add Shop Promo',
  'link.shopPromo.detail': 'Shop Promo Detail',
  'link.shopPromo.edit': 'Edit Shop Promo',
  'link.shopScrollingBanner': 'Shop Scrolling Banner',
  'link.shopScrollingBanner.add': 'Add Shop Scrolling Banner',
  'link.shopScrollingBanner.detail': 'Shop Scrolling Banner Detail',
  'link.shopScrollingBanner.edit': 'Edit Shop Scrolling Banner',
  'link.shopHubMenu.addHubMenu': 'Add Shop Menu',
  'link.shopHubMenu.uploadHubMenu': 'Upload Shop Hub Menu',
  'link.shopVoucher.add': 'Add Shop Voucher',
  'link.shopVoucher.detail': 'Shop Voucher Detail',
  'link.shopVoucher.edit': 'Edit Shop Voucher',
  'link.sparePart': 'Spare Part',
  'link.sparePart.add': 'Add Spare Part',
  'link.sparePart.edit': 'Edit Spare Part',
  'link.stockPreparation': 'Stock Preparation',
  'link.stockPreparation.detail': 'Stock Preparation Detail',
  'link.task': 'Task',
  'link.taskAdd': 'Add Task',
  'link.taskAll': 'All Task',
  'link.taskAssign': 'Assign Task',
  'link.taskDetail': 'Detail Task',
  'link.taskEdit': 'Edit Task',
  'link.taskMy': 'My Task',
  'link.taskSchedule': 'Task Schedule',
  'link.taskWip': 'WIP Task',
  'link.taskReport.detail': 'Report Detail',
  'link.unit.add': 'Add Unit',
  'link.unitEdit': 'Edit Unit',
  'link.user': 'User',
  'link.user.detail': 'User Detail',
  'link.user.hubMapping': 'Outlet Mapping',
  'link.user.hubMappingEdit': 'Edit Outlet Mapping',
  'link.user.machineMapping': 'Machine Mapping',
  'link.user.machineMappingEdit': 'Edit Machine Mapping',
  'link.user.mapping': 'User Mapping',
  'link.user.merchantMapping': 'Merchant Mapping',
  'link.user.merchantMappingEdit': 'Edit Merchant Mapping',
  'link.user.partnerMapping': 'Partner Mapping',
  'link.user.partnerMappingEdit': 'Edit Partner Mapping',
  'link.user.paymentMapping': 'Payment Mapping',
  'link.user.paymentMappingEdit': 'Edit Payment Mapping',
  'link.userActivity': 'User Activity',
  'link.userAdd': 'Add User',
  'link.userDetail': 'Detail User',
  'link.userEdit': 'Edit User',

  'modal.confirmation.body': 'Are you sure you want to do this?',
  'modal.confirmation.cancel': 'Cancel',
  'modal.confirmation.title': 'Confirmation',
  'modal.confirmation.yes': 'Yes',
  'modal.close': 'Close',

  'placeholder.blank': ' ',
  'placeholder.cardCode.commaSeparatedCodesAndProductIds': 'CODE1,412',
  'placeholder.cimbDashboardFailRequest.ids': 'Insert ID, use enter for multiple IDs',
  'placeholder.cluster.name': 'Jakarta',
  'placeholder.company.address': 'Menara Palma',
  'placeholder.company.jsRewardBalance': '1000000',
  'placeholder.company.name': 'PT DMI',
  'placeholder.company.phoneNo': '021-12345678',
  'placeholder.date': 'DD-MM-YYYY',
  'placeholder.datetime': 'DD-MM-YYYY HH:mm',
  'placeholder.department.name': 'HRD',
  'placeholder.department.description': 'Human Resources Development',
  'placeholder.dynamicsFailRequest.ids': 'Insert ID, use enter for multiple IDs',
  'placeholder.empty': '-',
  'placeholder.hub.additionalInformation': 'Additional Information',
  'placeholder.hub.address': 'Address',
  'placeholder.hub.buildingName': 'Building Name',
  'placeholder.hub.city': 'City',
  'placeholder.hub.companyName': 'Company Name',
  'placeholder.hub.district': 'District',
  'placeholder.hub.feePerCup': 'Fee per Cup',
  'placeholder.hub.floor': 'Floor',
  'placeholder.hub.latitude': 'Latitude',
  'placeholder.hub.longitude': 'Longitude',
  'placeholder.hub.minimumAmount': 'Min. Amount',
  'placeholder.hub.monthlyRentalFee': 'Monthly Rental Fee',
  'placeholder.hub.paymentTerms': 'Payment Terms',
  'placeholder.hub.postalCode': 'Postal Code',
  'placeholder.hub.priceSettingValue': 'Amount',
  'placeholder.hub.province': 'Province',
  'placeholder.hub.revenue': 'Revenue',
  'placeholder.hub.targetCup': '0',
  'placeholder.hub.targetRevenue': '0',
  'placeholder.hub.subDistrict': 'Sub District',
  'placeholder.hubType.description': 'Brief description of the type',
  'placeholder.hubType.name': 'e.g. Office Building',
  'placeholder.ingredient.name': 'Ingredient’s name',
  'placeholder.ingredient.orderNr': 'Display ID. Eg: 1, 2, 3, etc',
  'placeholder.ingredient.remark': 'Ingredient’s remark',
  'placeholder.input.dateTime.default': 'DD-MM-YYYY HH:mm',
  'placeholder.latteConfig.carRefillTime': '10',
  'placeholder.latteConfig.minTravelTime': '5',
  'placeholder.latteConfig.carRefillerMaxDistance': '45',
  'placeholder.latteConfig.refillerMaxGallon': '45',
  'placeholder.latteConfig.refillerRefillTime': '10',
  'placeholder.latteConfig.refillerTravelSpeed': '20',
  'placeholder.latteConfig.carRefillerTravelSpeed': '20',
  'placeholder.latteConfig.minPercentageCapacity': '40',
  'placeholder.leave.note': 'Note',
  'placeholder.login.password': 'Type in your password',
  'placeholder.login.username': 'Type in your username',
  'placeholder.machine.address': 'Machine’s address',
  'placeholder.machine.factoryNumber': 'Machine’s factory number',
  'placeholder.machine.latitude': 'Current latitude',
  'placeholder.machine.locationArea': 'E.g. : SCBD, Kuningan',
  'placeholder.machine.locationType': 'E.g. : Office, Hospital, University',
  'placeholder.machine.longitude': 'Current longitude',
  'placeholder.machine.mid': 'Machine ID',
  'placeholder.machine.name': 'Machine’s name',
  'placeholder.machine.postalCode': 'Numeric only. Eg: 12000',
  'placeholder.machine.savedLatitude': 'Machine’s latitude',
  'placeholder.machine.savedLongitude': 'Machine’s longitude',
  'placeholder.machine.simcardNo': '08123456789',
  'placeholder.machine.tid': 'TID',
  'placeholder.machine.vmcNo': 'Use letters and numbers',
  'placeholder.machineError.code': 'Error Code',
  'placeholder.machineError.description': 'Description',
  'placeholder.machineDraft.factoryNumber': 'Factory Number',
  'placeholder.machineDraft.vmcNo': 'VMC No',
  'placeholder.machineHub.merchantId': 'MID',
  'placeholder.machineHub.simcardNo': 'Sim Card No',
  'placeholder.machineHub.terminalId': 'TID',
  'placeholder.machinePayment.mid': 'MID',
  'placeholder.machinePayment.storeID': 'storeID',
  'placeholder.machinePayment.tid': 'TID',
  'placeholder.machinePayment.posID': 'posID',
  'placeholder.machineQr.name': 'Name',
  'placeholder.machineStockIn.currentStock': '50',
  'placeholder.machineStockIn.maxStock': '100',
  'placeholder.machineStockIn.minStock': '10',
  'placeholder.machineStockOut.currentStock': '50',
  'placeholder.machineStockOut.maxStock': '100',
  'placeholder.machineStockOut.minStock': '10',
  'placeholder.machineType.description': 'Machine type’s description',
  'placeholder.machineType.initialName': 'Machine type’s initial name',
  'placeholder.machineType.name': 'Machine type’s name',
  'placeholder.machineTypeStock.ingredient.name': 'Ingredient Name',
  'placeholder.machineTypeStock.maxStock.low': 'Max Stock Low',
  'placeholder.machineTypeStock.maxStock.medium': 'Max Stock Medium',
  'placeholder.machineTypeStock.maxStock.high': 'Max Stock High',
  'placeholder.midtrans.clientKey': 'Midtrans’s Client Key in Midtrans',
  'placeholder.midtrans.description': 'Midtrans’s description',
  'placeholder.midtrans.merchantId': 'Midtrans’s Merchant ID in Midtrans',
  'placeholder.midtrans.serverKey': 'Midtrans’s Server Key in Midtrans',
  'placeholder.optional': '(Optional)',
  'placeholder.packaging.description': 'Packaging’s description',
  'placeholder.packaging.name': 'Packaging’s name',
  'placeholder.packaging.quantity': '100',
  'placeholder.pos.cart.customerName': `New customer's name`,
  'placeholder.pos.cart.customerSearch': `Search existing customer's name`,
  'placeholder.pos.cart.payment.notes': 'Notes',
  'placeholder.product.cogs': '10,000',
  'placeholder.product.weight': '0',
  'placeholder.product.maxDuration': '120',
  'placeholder.product.minDuration': '0',
  'placeholder.product.name': 'Menu name',
  'placeholder.productSet.name': 'Menu set name',
  'placeholder.role.description': 'Role’s description',
  'placeholder.role.name': 'Role’s name',
  'placeholder.refillerSchedule.maxDistanceRefiller': 'Refiller Max Distance',
  'placeholder.refillerSchedule.carNumber': 'Car Number',
  'placeholder.rewardRule.amount': 'Amount',
  'placeholder.rewardRule.customerIds': 'Customer Id seperated comma',
  'placeholder.rewardRule.description': 'Rule Description',
  'placeholder.rewardRule.name': 'Rule Name',
  'placeholder.rewardRule.rfidCardNos': 'Rfid Card No seperated comma',
  'placeholder.rewardRule.usageLimit': 'Limit',
  'placeholder.rfidCard.name': 'Name',
  'placeholder.rfidCard.no': 'Card No.',
  'placeholder.shop.customer.birthdate': 'Date of Birth',
  'placeholder.shop.customer.email': 'Email',
  'placeholder.shop.customer.gender': 'Gender',
  'placeholder.shop.customer.name': 'Name',
  'placeholder.shop.customer.phoneNo': 'Phone No.',
  'placeholder.shop.deal.description': 'Description',
  'placeholder.shop.deal.name': 'Name',
  'placeholder.shop.deal.pathName': 'Path Name',
  'placeholder.shop.faq.description': 'FAQ Answer',
  'placeholder.shop.faq.title': 'FAQ Question',
  'placeholder.shop.faq.type': 'FAQ',
  'placeholder.shop.hub.address': 'Address',
  'placeholder.shop.hub.city': 'City',
  'placeholder.shop.hub.hubOperationals': 'Outlet Operationals',
  'placeholder.shop.hub.latitude': 'Latitude',
  'placeholder.shop.hub.longitude': 'Longitude',
  'placeholder.shop.hub.name': 'Name',
  'placeholder.shop.hub.no': 'Outlet Id',
  'placeholder.shop.hub.phoneNo': 'Phone No.',
  'placeholder.shop.hub.postalCode': 'Postal Code',
  'placeholder.shop.hubMenu.discountedPrice': '0',
  'placeholder.shop.hubMenu.discountStartAt': 'Discount Start Date',
  'placeholder.shop.hubMenu.discountEndAt': 'Discount End Date',
  'placeholder.shop.hub.targetItem': '0',
  'placeholder.shop.hub.targetRevenue': '0',
  'placeholder.shop.hub.targetOrder': '0',
  'placeholder.shop.hubStock.currentStock': '0',
  'placeholder.shop.hubStock.maxStock': '0',
  'placeholder.shop.hubStock.minStock': '0',
  'placeholder.shop.homeAnnouncement.description': 'Home Announcement',
  'placeholder.shop.membership.imageUrl': 'Image URL',
  'placeholder.shop.membership.levelName': 'e.g. Basic',
  'placeholder.shop.membership.privilege':
    'Please separate each privilege point (if there is any) with an enter, see below example\nGet 50% discount all items\nGet 100% cashback on Monday',
  'placeholder.shop.membershipPromo.description': 'Optional: a brief description of the promo',
  'placeholder.shop.membershipPromo.referralName': 'e.g. Silver, must be unique',
  'placeholder.shop.menu.cogs': 'COGS',
  'placeholder.shop.menu.description': 'Description',
  'placeholder.shop.menu.detailImage': 'Detail Image',
  'placeholder.shop.menu.image': 'Image',
  'placeholder.shop.menu.menuCategory': 'Category',
  'placeholder.shop.menu.menuCustomizationItem': 'Customization',
  'placeholder.shop.menu.merchant': 'Merchant',
  'placeholder.shop.menu.name': 'Name',
  'placeholder.shop.menu.price': 'Price',
  'placeholder.shop.menu.productId': 'Product ID',
  'placeholder.shop.menu.totalItem': '0',
  'placeholder.shop.merchant.name': 'Merchant’s name',
  'placeholder.shop.partner.name': 'Name',
  'placeholder.shop.popupBanner.manual.customerType': 'Customer Type',
  'placeholder.shop.popupBanner.manual.image': 'Image',
  'placeholder.shop.popupBanner.manual.imageUrl': 'Image Url',
  'placeholder.shop.popupBanner.manual.name': 'Name',
  'placeholder.shop.popupBanner.manual.url': 'Url',
  'placeholder.shop.posPromo.customerIds': '1,2,3',
  'placeholder.shop.posPromo.customerLastOrder': '1',
  'placeholder.shop.posPromo.customerNumberOfOrders': '1',
  'placeholder.shop.posPromo.customerTotalAmount': '1',
  'placeholder.shop.posPromo.description': 'Description',
  'placeholder.shop.posPromo.discountAmount': '0',
  'placeholder.shop.posPromo.maximumDiscountAmount': '0',
  'placeholder.shop.posPromo.minimumAmount': '0',
  'placeholder.shop.posPromo.minimumItem': '0',
  'placeholder.shop.posPromo.name': 'Name',
  'placeholder.shop.posPromo.quantityPerCustomer': '0',
  'placeholder.shop.posPromo.specificCustomers': 'Input ID (example: 1111,2222)',
  'placeholder.shop.posPromo.title': 'Title',
  'placeholder.shop.posPromo.totalQuantity': '1',
  'placeholder.shop.posPromo.usageLimitPerCustomer': '0',
  'placeholder.shop.posPromoPartner.name': 'Name',
  'placeholder.shop.pwaConfiguration.hubGopayAdditionalInfo': '( Cashback  10% )',
  'placeholder.shop.pwaConfiguration.hubLinkAjaAdditionalInfo': '( Cashback  10% )',
  'placeholder.shop.pwaConfiguration.hubShopeePayAdditionalInfo': '( Cashback  10% )',
  'placeholder.shop.pwaConfiguration.machineGopayAdditionalInfo': '( Cashback  10% )',
  'placeholder.shop.pwaConfiguration.machineLinkAjaAdditionalInfo': '( Cashback  10% )',
  'placeholder.shop.pwaConfiguration.machineShopeePayAdditionalInfo': '( Cashback  10% )',
  'placeholder.shop.pwaConfiguration.maximumLoyaltyPointsUsedInPercentage': '50',
  'placeholder.shop.pwaConfiguration.popupBannerDelayTimeInSeconds': '10',
  'placeholder.shop.tnc.description': 'TnC Description',
  'placeholder.shop.tnc.title': 'TnC Title',
  'placeholder.shop.topping.cogs': '0',
  'placeholder.shop.topping.name': 'Name',
  'placeholder.shop.topping.price': '0',
  'placeholder.shop.topping.productId': 'Enter Product ID',
  'placeholder.shop.variant.name': 'Variant Name',
  'placeholder.shopDiscount.customerIds': '1,2,3',
  'placeholder.shopDiscount.customerLastOrder': '1',
  'placeholder.shopDiscount.customerNumberOfOrders': '1',
  'placeholder.shopDiscount.customerTotalAmount': '1',
  'placeholder.shopDiscount.description': 'Description',
  'placeholder.shopDiscount.discountAmount': '0',
  'placeholder.shopDiscount.imageUrl': 'Image URL',
  'placeholder.shopDiscount.maximumDiscountAmount': '0',
  'placeholder.shopDiscount.minimumAmount': '0',
  'placeholder.shopDiscount.minimumItem': '0',
  'placeholder.shopDiscount.name': 'Name',
  'placeholder.shopDiscount.numberOfVoucherCode': '0',
  'placeholder.shopDiscount.quantityPerCustomer': '0',
  'placeholder.shopDiscount.quantityPerVoucherCode': '0',
  'placeholder.shopDiscount.specificCustomers': 'Input ID (example: 1111,2222)',
  'placeholder.shopDiscount.title': 'Title',
  'placeholder.shopDiscount.totalQuantity': '1',
  'placeholder.shopDiscount.usageLimitPerCustomer': '0',
  'placeholder.shopDiscount.voucherCode': 'JSABC123',
  'placeholder.shopDiscount.voucherCodePrefix': 'JS',
  'placeholder.shopDiscount.voucherDuration': '0',
  'placeholder.shopDiscount.voucherQuantity': '0',
  'placeholder.shop.menuCategory.description': 'Menu Category’s description',
  'placeholder.shop.menuCategory.name': 'Menu Category’s name',
  'placeholder.shop.MenuCustomization.name': 'Menu Customization’s name',
  'placeholder.shopMenuCustomizationItem.name': 'Item’s Name',
  'placeholder.shop.menuSubcategory.name': 'Menu Subcategory’s name',
  'placeholder.shopNotification.customerIds': '1,2',
  'placeholder.shopNotification.customerLastOrder': 'Customer last order',
  'placeholder.shopNotification.customerNumberOfOrders': 'Customer number of orders',
  'placeholder.shopNotification.customerTotalAmount': 'Customer total amount',
  'placeholder.shopNotification.descriptionPreview': 'Description Preview',
  'placeholder.shopNotification.detail.buttonName': 'Button Name',
  'placeholder.shopNotification.detail.buttonUrl': 'Button URL',
  'placeholder.shopNotification.detail.description': 'Description',
  'placeholder.shopNotification.iconUrl': 'Icon URL',
  'placeholder.shopNotification.imageUrl': 'Image URL',
  'placeholder.shopNotification.title': 'Title',
  'placeholder.shopNotification.totalOrderAmount': 'Total order amount',
  'placeholder.shopNotification.url': 'URL',
  'placeholder.shopPosSalesType.description': 'Description',
  'placeholder.shopPosSalesType.name': 'Name',
  'placeholder.shopScrollingBanner.imageUrl': 'Upload image or specify image URL',
  'placeholder.shopScrollingBanner.name': 'Identifiable name for your banner',
  'placeholder.shopScrollingBanner.priority': 'Specify banner priority',
  'placeholder.shopScrollingBanner.url': 'Target URL when user click (optional)',
  'placeholder.shopThirdPartyPromo.endDate': 'End Date',
  'placeholder.shopThirdPartyPromo.image': 'Image',
  'placeholder.shopThirdPartyPromo.name': 'Third Party Promo`s name',
  'placeholder.shopThirdPartyPromo.startDate': 'Start Date',
  'placeholder.shopThirdPartyPromo.url': 'Url',
  'placeholder.sparePart.name': 'Spare part’s name',
  'placeholder.sparePart.description': 'Spare part’s description',
  'placeholder.task.description': 'Task’s description',
  'placeholder.task.note': 'Add note here',
  'placeholder.task.note.decline': 'Please state your reason for declining this task',
  'placeholder.task.title': 'Task’s title',
  'placeholder.time': 'HH:mm',
  'placeholder.unit.description': 'Unit’s description',
  'placeholder.unit.name': 'Unit’s name',
  'placeholder.user.confirmPassword': 'Re-type the password',
  'placeholder.user.email': 'example@mail.com',
  'placeholder.user.id': 'ID',
  'placeholder.user.latitude': 'Latitude',
  'placeholder.user.longitude': 'Longitude',
  'placeholder.user.name': 'User’s full name',
  'placeholder.user.password': 'Type in the password',
  'placeholder.user.phoneNo': 'User’s phone number',
  'placeholder.user.role': 'Role',
  'placeholder.user.username': 'Use letters and numbers',

  'sidebar.analytics': 'Analytics',
  'sidebar.analytics.logistic': 'Logistic',
  'sidebar.analytics.machine': 'Machine',
  'sidebar.analytics.outlet': 'Outlet',
  'sidebar.analytics.pwa': 'PWA',
  'sidebar.cardCode': 'Card Code',
  'sidebar.cardReaderSettlement': 'Card Reader',
  'sidebar.cardReaderSettlementData': 'Settlement Data',
  'sidebar.cardReaderSettlementFile': 'Settlement File',
  'sidebar.cimbDashboard': 'CIMB Dashboard',
  'sidebar.cluster': 'Cluster',
  'sidebar.coffeeMachine': 'Coffee Machine',
  'sidebar.company': 'Company',
  'sidebar.concisedOrder': 'Order Concised',
  'sidebar.dashboard': 'Dashboard',
  'sidebar.department': 'Department',
  'sidebar.dynamics': 'Dynamics',
  'sidebar.general': 'General',
  'sidebar.hub': 'Location',
  'sidebar.hubType': 'Location Type',
  'sidebar.ingredient': 'Ingredient',
  'sidebar.latteConfig': 'AI Configuration',
  'sidebar.leave': 'Leave',
  'sidebar.machine': 'Machine',
  'sidebar.machineConfiguration': 'Machine Configuration',
  'sidebar.machineError': 'Machine Error',
  'sidebar.machinePayment': 'Machine Payment',
  'sidebar.machineQr': 'Qr Code',
  'sidebar.machineStatusError': 'Machine Error Task',
  'sidebar.machineType': 'Machine Type',
  'sidebar.machineTypeDisplay': 'Machine Type Display',
  'sidebar.menu': 'Menu',
  'sidebar.menuIngredient': 'Menu & Ingredient',
  'sidebar.menuSet': 'Menu Set',
  'sidebar.midtrans': 'Midtrans Account',
  'sidebar.myProfile': 'My Profile',
  'sidebar.order': 'Order',
  'sidebar.orderLog': 'Order Log',
  'sidebar.orderSummary': 'Order Summary',
  'sidebar.packaging': 'Packaging',
  'sidebar.paymentProvider': 'Payment Provider',
  'sidebar.paymentMethod': 'Payment Method',
  'sidebar.profitabilityReport': 'Profitability',
  'sidebar.pwaConfiguration': 'PWA Configuration',
  'sidebar.pwaMachine': 'PWA x Machine',
  'sidebar.pwaOutlet': 'PWA x Outlet',
  'sidebar.pwa': 'PWA',
  'sidebar.recipe': 'Recipe',
  'sidebar.refiller': 'Refiller',
  'sidebar.refillerSchedule': 'Refiller Schedule',
  'sidebar.reward': 'JS Reward',
  'sidebar.rewardOrder': 'Reward Order',
  'sidebar.rewardRule': 'Rule',
  'sidebar.role': 'Role',
  'sidebar.shop': 'Shop',
  'sidebar.shopAboutUs': 'About Us',
  'sidebar.shopBarista': 'Barista',
  'sidebar.shopContent': 'Content',
  'sidebar.shopCourier': 'Courier Schedule',
  'sidebar.shopCourierOrder': 'Courier Task',
  'sidebar.shopCustomer': 'Customer',
  'sidebar.shopDeal': 'Deals',
  'sidebar.shopDelivery': 'Delivery',
  'sidebar.shopDeliveryConfiguration': 'Configuration',
  'sidebar.shopFaq': 'FAQ',
  'sidebar.shopGeneral': 'PWA General',
  'sidebar.shopHomeAnnouncement': 'Announcement',
  'sidebar.shopHomeBackground': 'Home Background',
  'sidebar.shopHub': 'Outlet',
  'sidebar.shopLoyaltyPointHistory': 'Point History',
  'sidebar.shopMembership': 'Membership',
  'sidebar.shopMembershipPromo': 'Membership Promo',
  'sidebar.shopMenu': 'Menu',
  'sidebar.shopMenuCategory': 'Category',
  'sidebar.shopMenuCustomization': 'Customization',
  'sidebar.shopMenuDisplayCategory': 'Display Category',
  'sidebar.shopMerchant': 'Merchant',
  'sidebar.shopNotification': 'Notification',
  'sidebar.shopOrderCompleted': 'Order',
  'sidebar.shopOrderIncompleted': 'Incomplete Order',
  'sidebar.shopOrderOutlet': 'Outlet',
  'sidebar.shopOrderOutletSummary': 'Outlet Summary',
  'sidebar.shopPartner': 'Partner',
  'sidebar.shopPaymentProvider': 'Payment Provider',
  'sidebar.shopPopupBanner': 'Popup Banner',
  'sidebar.shopPosPromotion': 'POS Promotion',
  'sidebar.shopPosSalesType': 'POS Sales Type',
  'sidebar.shopProduct': 'Product',
  'sidebar.shopProductPromotion': 'PWA x Machine Promotions',
  'sidebar.shopProductDisplayCategory': 'Product Category',
  'sidebar.shopProductMachineTypeDisplay': 'Product Machine',
  'sidebar.shopProductOrder': 'Order Card Code',
  'sidebar.shopPromotion': 'PWA x Outlet Promotions',
  'sidebar.shopPromotions': 'Promotions',
  'sidebar.shopPwaConfiguration': 'PWA Configuration',
  'sidebar.shopRecipe': 'Recipe',
  'sidebar.shopScrollingBanner': 'Scrolling Banner',
  'sidebar.shopTask.me': 'My Outlet Task',
  'sidebar.shopTnc': 'TnC',
  'sidebar.shopTopping': 'Topping',
  'sidebar.shopVariant': 'Variant',
  'sidebar.sparePart': 'Spare Part',
  'sidebar.stockPreparation': 'Stock Preparation',
  'sidebar.task': 'Task',
  'sidebar.taskAll': 'All Task',
  'sidebar.taskMine': 'My Task',
  'sidebar.taskSchedule': 'Task Schedule',
  'sidebar.unit': 'Unit',
  'sidebar.user': 'User',
  'sidebar.userActivity': 'User Activity',
  'sidebar.userRole': 'User & Role',
  'sidebar.vendingMachine': 'Machine',

  'stockPreparationDetail.coffeeMachine.title': 'Coffee Machine',
  'stockPreparationDetail.snackMachine.title': 'Snack Machine',

  'task.start.confirmation.body': 'Are you sure you want to start this task?',
  'task.start.confirmation.title': 'Starting task',
  'task.start.confirmation.yes': 'Start',

  'text.not.found': '404',
  'text.value': 'Value: ',

  'validation.alphanumeric': 'Must be a combination of letters and numbers.',
  'validation.alphanumericOnly': 'Special characters are not allowed.',
  'validation.buildingAndCompanyNameRequired': 'Either Building or Company Name must be filled',
  'validation.capitalAlphanumericOnly': 'Only capital letters and numbers are allowed.',
  'validation.eightCharMinimum': 'Minimum 8 characters.',
  'validation.email': 'Invalid email format.',
  'validation.greaterThanOne': 'Must be greater than 1',
  'validation.greaterThanZero': 'Must be greater than 0',
  'validation.insufficientStock': 'Insufficient stock',
  'validation.invalidNumber': 'Invalid number',
  'validation.invalidStartDateOrEndDate': 'Invalid start date or end date',
  'validation.latitude': 'Invalid latitude',
  'validation.length.4': 'Must 4 characters.',
  'validation.longitude': 'Invalid longitude',
  'validation.machineFactoryNumberPattern':
    'The field can only contain letters (a-z, A-Z), numbers (0-9), and hyphens (-)',
  'validation.maxLength.2': 'Maximum length 2 digits',
  'validation.maxLength.12': 'Maximum length 12 digits',
  'validation.maxLength.32': 'Maximum length 32 characters',
  'validation.maxLength.50': 'Maximum length 50 characters',
  'validation.maxNumber': 'Maximum value {number}',
  'validation.maxPeriodSmaller': 'The end period should be greater than the start period',
  'validation.maximumDurationExceeded': 'Maximum duration is 31 days',
  'validation.minMaxCup': 'Minimum cup must lower than Maximum cup',
  'validation.lowMediumHigh': 'Medium must between low and high',
  'validation.minPeriod': 'The start period should be greather than now',
  'validation.minPeriodGreater': 'The start period should be smaller than the end period',
  'validation.nonNegative': 'Should not be negative',
  'validation.notEmptyArray': 'Should not be empty',
  'validation.numericOnly': 'Value of this field must be numeric.',
  'validation.passwordNotMatch': 'Password does not match.',
  'validation.phone': 'Invalid phone format.',
  'validation.product.cookingDuration': 'It must be greater than mininum cooking duration.',
  'validation.required': 'This field is required.',
  'validation.time.start.end': 'Open must less tha close',
  'validation.twoChar': 'Must be 2 characters',
  'validation.username': 'Invalid username format.',
  'validation.repeatedIngredients': 'Got repeated ingredients',
  'validation.returnQuantityGreater': 'Return quantity should not be greater than its current stock',

  'wip.action.addIngredients': 'Add Ingredients',
  'wip.machineStockInReturns.title': 'Return Refiller',
  'wip.machineStockOutReturns.title': 'Return Car Refiller',
  'wip.action.takePhoto': 'Take a photo',
  'wip.action.retakePhoto': 'Retake photo',
  'wip.action.addSparePart': 'Add Spare Part'
};
