export interface Route {
  path: string;
  requiredAuthority?: string;
}

export const DASHBOARD: Route = { path: '/' };
export const LOGIN: Route = { path: '/login' };

export const ANALYTICS_LOGISTIC: Route = { path: '/analytics-logistic', requiredAuthority: 'VIEW_ANALYTICS_LOGISTIC' };
export const ANALYTICS_MACHINE: Route = { path: '/analytics-machine', requiredAuthority: 'VIEW_ANALYTICS_MACHINE' };
export const ANALYTICS_OUTLET: Route = { path: '/analytics-outlet', requiredAuthority: 'VIEW_ANALYTICS_OUTLET' };
export const ANALYTICS_PWA: Route = { path: '/analytics-pwa', requiredAuthority: 'VIEW_ANALYTICS_PWA' };

export const ROLE: Route = { path: '/role', requiredAuthority: 'VIEW_ROLE' };
export const ROLE_ADD: Route = { path: `${ROLE.path}/add`, requiredAuthority: 'CREATE_ROLE' };
export const ROLE_EDIT: Route = { path: `${ROLE.path}/:roleId/edit`, requiredAuthority: 'UPDATE_ROLE' };

export const USER: Route = { path: '/user', requiredAuthority: 'VIEW_USER' };
export const USER_ACTIVITY: Route = { path: '/user-activity', requiredAuthority: 'VIEW_USER_ACTIVITY' };
export const USER_ADD: Route = { path: `${USER.path}/add`, requiredAuthority: 'CREATE_USER' };
export const USER_DETAIL: Route = { path: `${USER.path}/:userId`, requiredAuthority: 'VIEW_USER' };
export const USER_EDIT: Route = { path: `${USER_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_USER' };
export const USER_MAPPING: Route = { path: `${USER_DETAIL.path}/mapping` };
export const USER_SHOP_HUB_MAPPING: Route = { path: `${USER_MAPPING.path}`, requiredAuthority: 'VIEW_USER_SHOP_HUB' };
export const USER_SHOP_HUB_MAPPING_EDIT: Route = {
  path: `${USER_MAPPING.path}/store-mapping/edit`,
  requiredAuthority: 'UPDATE_USER_SHOP_HUB'
};
export const USER_PAYMENT_MAPPING: Route = { path: `${USER_MAPPING.path}`, requiredAuthority: 'VIEW_USER_PAYMENT' };
export const USER_PAYMENT_MAPPING_EDIT: Route = {
  path: `${USER_MAPPING.path}/payment-mapping/edit`,
  requiredAuthority: 'UPDATE_USER_PAYMENT'
};
export const USER_SHOP_MERCHANT_MAPPING: Route = {
  path: `${USER_MAPPING.path}`,
  requiredAuthority: 'VIEW_USER_SHOP_MERCHANT'
};
export const USER_SHOP_MERCHANT_MAPPING_EDIT: Route = {
  path: `${USER_MAPPING.path}/merchant-mapping/edit`,
  requiredAuthority: 'UPDATE_USER_SHOP_MERCHANT'
};
export const USER_MACHINE_MAPPING: Route = {
  path: `${USER_MAPPING.path}`,
  requiredAuthority: 'VIEW_USER_MACHINE_MAPPING'
};
export const USER_MACHINE_MAPPING_EDIT: Route = {
  path: `${USER_MAPPING.path}/machine-mapping/edit`,
  requiredAuthority: 'UPDATE_USER_MACHINE_MAPPING'
};
export const USER_SHOP_PARTNER_MAPPING: Route = {
  path: `${USER_MAPPING.path}`,
  requiredAuthority: 'VIEW_USER_SHOP_PARTNER'
};
export const USER_SHOP_PARTNER_MAPPING_EDIT: Route = {
  path: `${USER_MAPPING.path}/partner-mapping/edit`,
  requiredAuthority: 'UPDATE_USER_SHOP_PARTNER'
};
export const USER_COMPANY_MAPPING: Route = {
  path: `${USER_MAPPING.path}`,
  requiredAuthority: 'VIEW_USER_COMPANY_MAPPING'
};
export const USER_COMPANY_MAPPING_EDIT: Route = {
  path: `${USER_MAPPING.path}/company-mapping/edit`,
  requiredAuthority: 'UPDATE_USER_COMPANY_MAPPING'
};

export const UNIT: Route = { path: '/unit', requiredAuthority: 'VIEW_UNIT' };
export const UNIT_ADD: Route = { path: `${UNIT.path}/add`, requiredAuthority: 'CREATE_UNIT' };
export const UNIT_DETAIL: Route = { path: `${UNIT.path}/:unitId`, requiredAuthority: 'VIEW_UNIT' };
export const UNIT_EDIT: Route = { path: `${UNIT_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_UNIT' };

export const HUB: Route = { path: '/hub', requiredAuthority: 'VIEW_HUB' };
export const HUB_ADD: Route = { path: `${HUB.path}/add`, requiredAuthority: 'CREATE_HUB' };
export const HUB_DETAIL: Route = { path: `${HUB.path}/:hubId`, requiredAuthority: 'VIEW_HUB' };
export const HUB_EDIT: Route = { path: `${HUB_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_HUB' };
export const HUB_MACHINE: Route = { path: `${HUB_DETAIL.path}/machine` };
export const HUB_MACHINE_UNLOAD: Route = {
  path: `${HUB_MACHINE.path}/:machineHubId/unload`,
  requiredAuthority: 'UPDATE_HUB'
};
export const HUB_MACHINE_ADD: Route = { path: `${HUB_MACHINE.path}/add`, requiredAuthority: 'UPDATE_HUB' };
export const HUB_MACHINE_EDIT: Route = {
  path: `${HUB_MACHINE.path}/:machineHubId/edit`,
  requiredAuthority: 'UPDATE_HUB'
};

export const INGREDIENT: Route = { path: '/ingredient', requiredAuthority: 'VIEW_INGREDIENT' };
export const INGREDIENT_ADD: Route = { path: `${INGREDIENT.path}/add`, requiredAuthority: 'CREATE_INGREDIENT' };
export const INGREDIENT_EDIT: Route = {
  path: `${INGREDIENT.path}/:ingredientId/edit`,
  requiredAuthority: 'UPDATE_INGREDIENT'
};

export const LATTE_CONFIG: Route = { path: '/ai-config', requiredAuthority: 'VIEW_LATTE_CONFIG' };
export const LATTE_CONFIG_EDIT: Route = { path: `${LATTE_CONFIG.path}/edit`, requiredAuthority: 'UPDATE_LATTE_CONFIG' };

export const LEAVE: Route = { path: '/leave', requiredAuthority: 'VIEW_LEAVE' };
export const LEAVE_ADD: Route = { path: `${LEAVE.path}/add`, requiredAuthority: 'CREATE_LEAVE' };
export const LEAVE_DETAIL: Route = { path: `${LEAVE.path}/:leaveId`, requiredAuthority: 'VIEW_LEAVE' };
export const LEAVE_EDIT: Route = { path: `${LEAVE_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_LEAVE' };

export const MENU: Route = { path: '/menu', requiredAuthority: 'VIEW_PRODUCT' };
export const MENU_ADD: Route = { path: `${MENU.path}/add`, requiredAuthority: 'CREATE_PRODUCT' };
export const MENU_DETAIL: Route = { path: `${MENU.path}/:productId`, requiredAuthority: 'VIEW_PRODUCT' };
export const MENU_INGREDIENT_EDIT: Route = {
  path: `${MENU_DETAIL.path}/ingredient/:ingredientId/edit`,
  requiredAuthority: 'UPDATE_PRODUCT'
};
export const MENU_EDIT: Route = { path: `${MENU_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_PRODUCT' };

export const ORDER_SUMMARY: Route = { path: '/order-summary', requiredAuthority: 'VIEW_ORDER_SUMMARY' };

export const PROFILE: Route = { path: '/profile', requiredAuthority: 'VIEW_MY_PROFILE' };
export const PROFILE_EDIT: Route = { path: `${PROFILE.path}/edit`, requiredAuthority: 'UPDATE_MY_PROFILE' };

export const MACHINE_TYPE: Route = { path: '/machine-type', requiredAuthority: 'VIEW_MACHINE_TYPE' };
export const MACHINE_TYPE_ADD: Route = { path: `${MACHINE_TYPE.path}/add`, requiredAuthority: 'CREATE_MACHINE_TYPE' };
export const MACHINE_TYPE_DETAIL: Route = {
  path: `${MACHINE_TYPE.path}/:machineTypeId`,
  requiredAuthority: 'VIEW_MACHINE_TYPE'
};
export const MACHINE_TYPE_EDIT: Route = {
  path: `${MACHINE_TYPE_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_MACHINE_TYPE'
};
export const MACHINE_TYPE_STOCK_LEVEL_CUP_EDIT: Route = {
  path: `${MACHINE_TYPE_DETAIL.path}/level/edit`,
  requiredAuthority: 'UPDATE_MACHINE_TYPE_WEEKLY_CUP_SOLD'
};
export const MACHINE_TYPE_STOCK_ADD: Route = {
  path: ``,
  requiredAuthority: 'CREATE_MACHINE_TYPE_STOCK'
};
export const MACHINE_TYPE_STOCK_OUT_EDIT: Route = {
  path: `${MACHINE_TYPE_DETAIL.path}/stock-out/:machineTypeStockId/edit`,
  requiredAuthority: 'UPDATE_MACHINE_TYPE_STOCK'
};
export const MACHINE_TYPE_STOCK_IN_EDIT: Route = {
  path: `${MACHINE_TYPE_DETAIL.path}/stock-in/:machineTypeStockId/edit`,
  requiredAuthority: 'UPDATE_MACHINE_TYPE_STOCK'
};

export const MACHINE_TYPE_DISPLAY: Route = {
  path: '/display-machine-type',
  requiredAuthority: 'VIEW_MACHINE_TYPE_DISPLAY'
};
export const MACHINE_TYPE_DISPLAY_ADD: Route = {
  path: `${MACHINE_TYPE_DISPLAY.path}/add`,
  requiredAuthority: 'CREATE_MACHINE_TYPE_DISPLAY'
};
export const MACHINE_TYPE_DISPLAY_DETAIL: Route = {
  path: `${MACHINE_TYPE_DISPLAY.path}/:machineTypeDisplayId`,
  requiredAuthority: 'VIEW_MACHINE_TYPE_DISPLAY'
};
export const MACHINE_TYPE_DISPLAY_EDIT: Route = {
  path: `${MACHINE_TYPE_DISPLAY_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_MACHINE_TYPE_DISPLAY'
};

export const MACHINE: Route = { path: '/machine', requiredAuthority: 'VIEW_MACHINE' };
export const MACHINE_DETAIL: Route = { path: `${MACHINE.path}/machine/:machineId`, requiredAuthority: 'VIEW_MACHINE' };
export const MACHINE_EDIT: Route = { path: `${MACHINE_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_MACHINE' };

export const MACHINE_STATUS_ERROR: Route = {
  path: `${MACHINE.path}-status-error`,
  requiredAuthority: 'VIEW_MACHINE_ERROR'
};
export const MACHINE_DRAFT: Route = { path: '/machine', requiredAuthority: 'VIEW_MACHINE_DRAFT' };
export const MACHINE_DRAFT_EDIT: Route = {
  path: `${MACHINE_DRAFT.path}/machine-draft/:machineDraftId/edit`,
  requiredAuthority: 'CREATE_MACHINE'
};

export const FINISH_INVESTIGATION: Route = { path: ``, requiredAuthority: 'FINISH_INVESTIGATION' };

export const MACHINE_ERROR: Route = { path: `/machine-error`, requiredAuthority: 'VIEW_MACHINE_ERROR' };
export const MACHINE_ERROR_ADD: Route = {
  path: `${MACHINE_ERROR.path}/add`,
  requiredAuthority: 'CREATE_MACHINE_ERROR'
};
export const MACHINE_ERROR_DETAIL: Route = {
  path: `${MACHINE_ERROR.path}/:machineErrorId`,
  requiredAuthority: 'VIEW_MACHINE_ERROR'
};
export const MACHINE_ERROR_EDIT: Route = {
  path: `${MACHINE_ERROR_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_MACHINE_ERROR'
};
export const MACHINE_ERROR_DELETE: Route = {
  path: '',
  requiredAuthority: 'DELETE_MACHINE_ERROR'
};

export const MACHINE_MENU_ADD: Route = {
  path: `${MACHINE_DETAIL.path}`,
  requiredAuthority: 'CREATE_PRODUCT_IN_MACHINE'
};
export const MACHINE_MENU_EDIT: Route = {
  path: `${MACHINE_DETAIL.path}/machineMenu/:machineMenuId/edit`,
  requiredAuthority: 'UPDATE_PRODUCT_IN_MACHINE'
};

export const MACHINE_PAYMENT: Route = { path: '/machine-payment', requiredAuthority: 'VIEW_MACHINE_PAYMENT' };
export const MACHINE_PAYMENT_DETAIL: Route = {
  path: `${MACHINE_PAYMENT.path}/:machinePaymentId`,
  requiredAuthority: 'VIEW_MACHINE_PAYMENT'
};
export const MACHINE_PAYMENT_ADD: Route = {
  path: `${MACHINE_PAYMENT.path}/add`,
  requiredAuthority: 'CREATE_MACHINE_PAYMENT'
};
export const MACHINE_PAYMENT_DELETE: Route = {
  path: '',
  requiredAuthority: 'DELETE_MACHINE_PAYMENT'
};

export const MACHINE_STOCK_IN: Route = { path: `${MACHINE_DETAIL.path}/stock-in` };
export const MACHINE_STOCK_IN_ADD: Route = {
  path: `${MACHINE_STOCK_IN.path}/add`,
  requiredAuthority: 'CREATE_MACHINE_STOCK_IN'
};
export const MACHINE_STOCK_IN_DETAIL: Route = {
  path: `${MACHINE_STOCK_IN.path}/:machineStockInId`,
  requiredAuthority: 'VIEW_MACHINE_STOCK_IN'
};
export const MACHINE_STOCK_IN_EDIT: Route = {
  path: `${MACHINE_STOCK_IN_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_MACHINE_STOCK_IN'
};
export const MACHINE_STOCK_IN_FILL: Route = {
  path: `${MACHINE_STOCK_IN.path}/fill`,
  requiredAuthority: 'FILL_MACHINE_STOCK_IN'
};

export const MACHINE_STOCK_OUT: Route = { path: `${MACHINE_DETAIL.path}/stock-out` };
export const MACHINE_STOCK_OUT_ADD: Route = {
  path: `${MACHINE_STOCK_OUT.path}/add`,
  requiredAuthority: 'CREATE_MACHINE_STOCK_OUT'
};
export const MACHINE_STOCK_OUT_DETAIL: Route = {
  path: `${MACHINE_STOCK_OUT.path}/:machineStockOutId`,
  requiredAuthority: 'VIEW_MACHINE_STOCK_OUT'
};
export const MACHINE_STOCK_OUT_EDIT: Route = {
  path: `${MACHINE_STOCK_OUT_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_MACHINE_STOCK_OUT'
};
export const MACHINE_STOCK_OUT_FILL: Route = {
  path: `${MACHINE_STOCK_OUT.path}/fill`,
  requiredAuthority: 'FILL_MACHINE_STOCK_OUT'
};

export const HUB_TYPE: Route = { path: '/hub-type', requiredAuthority: 'VIEW_HUB_TYPE' };
export const HUB_TYPE_ADD: Route = { path: `${HUB_TYPE.path}/add`, requiredAuthority: 'CREATE_HUB_TYPE' };
export const HUB_TYPE_DETAIL: Route = { path: `${HUB_TYPE.path}/:hubTypeId`, requiredAuthority: 'VIEW_HUB_TYPE' };
export const HUB_TYPE_EDIT: Route = { path: `${HUB_TYPE_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_HUB_TYPE' };

export const ORDER: Route = { path: '/order', requiredAuthority: 'VIEW_ORDER' };
export const ORDER_DETAIL: Route = { path: `${ORDER.path}/:orderId`, requiredAuthority: 'VIEW_ORDER' };
export const SHOP_PRODUCT_ORDER: Route = { path: '/order-card-code', requiredAuthority: 'VIEW_ORDER' };
export const SHOP_PRODUCT_ORDER_DETAIL: Route = {
  path: `${SHOP_PRODUCT_ORDER.path}/:shopProductOrderId`,
  requiredAuthority: 'VIEW_ORDER'
};

export const CONCISED_ORDER: Route = { path: '/order-concised', requiredAuthority: 'VIEW_CONCISED_ORDER' };
export const CONCISED_ORDER_DETAIL: Route = {
  path: `${CONCISED_ORDER.path}/:orderId`,
  requiredAuthority: 'VIEW_CONCISED_ORDER'
};

export const ORDER_LOG: Route = { path: '/order-log', requiredAuthority: 'VIEW_ORDER_LOG' };

export const TASK: Route = { path: '/task', requiredAuthority: 'VIEW_TASK' };
export const TASK_ALL: Route = { path: `${TASK.path}/all`, requiredAuthority: 'VIEW_ALL_TASKS' };
export const TASK_ALL_DETAIL: Route = { path: `${TASK_ALL.path}/:taskId`, requiredAuthority: 'VIEW_TASK' };
export const TASK_ASSIGN: Route = { path: `${TASK.path}/:taskId/assign`, requiredAuthority: 'VIEW_TASK' };
export const TASK_ADD: Route = { path: `${TASK.path}/add`, requiredAuthority: 'CREATE_TASK' };
export const TASK_DETAIL: Route = { path: `${TASK.path}/:taskId`, requiredAuthority: 'VIEW_TASK' };
export const TASK_EDIT: Route = { path: `${TASK_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_TASK' };
export const TASK_WIP: Route = { path: `${TASK_DETAIL.path}/wip`, requiredAuthority: 'UPDATE_TASK' };
export const TASK_SCHEDULE: Route = { path: `${TASK.path}/all-schedule`, requiredAuthority: 'VIEW_ALL_TASKS' };
export const TASK_REPORT_DETAIL: Route = {
  path: `${TASK.path}/report/:taskReportId`,
  requiredAuthority: 'VIEW_ALL_TASKS'
};

export const SHOP_TASK: Route = { path: '/shop-task', requiredAuthority: 'VIEW_TASK' };
export const SHOP_TASK_ADD: Route = { path: `${SHOP_TASK.path}/add`, requiredAuthority: 'CREATE_TASK' };
export const SHOP_TASK_DETAIL: Route = { path: `${SHOP_TASK.path}/:shopTaskId`, requiredAuthority: 'VIEW_TASK' };
export const SHOP_TASK_ASSIGN: Route = { path: `${SHOP_TASK_DETAIL.path}/assign`, requiredAuthority: 'UPDATE_TASK' };
export const SHOP_TASK_EDIT: Route = { path: `${SHOP_TASK_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_TASK' };
export const SHOP_TASK_REFILL: Route = { path: `${SHOP_TASK_DETAIL.path}/refill`, requiredAuthority: 'UPDATE_TASK' };

export const SPARE_PART: Route = { path: '/spare-part', requiredAuthority: 'VIEW_SPARE_PART' };
export const SPARE_PART_ADD: Route = { path: `${SPARE_PART.path}/add`, requiredAuthority: 'CREATE_SPARE_PART' };
export const SPARE_PART_EDIT: Route = {
  path: `${SPARE_PART.path}/:sparePartId/edit`,
  requiredAuthority: 'UPDATE_SPARE_PART'
};

export const PACKAGING: Route = { path: `/packaging`, requiredAuthority: 'VIEW_PACKAGING' };
export const PACKAGING_ADD: Route = { path: `${PACKAGING.path}/add`, requiredAuthority: 'CREATE_PACKAGING' };
export const PACKAGING_DETAIL: Route = { path: `${PACKAGING.path}/:packagingId`, requiredAuthority: 'VIEW_PACKAGING' };
export const PACKAGING_EDIT: Route = { path: `${PACKAGING_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_PACKAGING' };

export const REFILLER_SCHEDULE: Route = { path: '/refiller-schedule', requiredAuthority: 'VIEW_REFILLER_SCHEDULE' };
export const REFILLER_SCHEDULE_ADD: Route = {
  path: `${REFILLER_SCHEDULE.path}/add`,
  requiredAuthority: 'CREATE_REFILLER_SCHEDULE'
};
export const REFILLER_SCHEDULE_DETAIL: Route = {
  path: `${REFILLER_SCHEDULE.path}/:refillerScheduleId`,
  requiredAuthority: 'VIEW_REFILLER_SCHEDULE'
};
export const REFILLER_SCHEDULE_EDIT: Route = {
  path: `${REFILLER_SCHEDULE_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_REFILLER_SCHEDULE'
};
export const REFILLER_SCHEDULE_OUT_OF_REACH: Route = {
  path: `${REFILLER_SCHEDULE.path}/out-of-reach`,
  requiredAuthority: 'VIEW_REFILLER_SCHEDULE'
};
export const SHOP_MENU_DISPLAY_CATEGORY: Route = {
  path: '/shop-menu-display-category',
  requiredAuthority: 'VIEW_SHOP_MENU_DISPLAY_CATEGORY'
};
export const SHOP_MENU_DISPLAY_CATEGORY_ADD: Route = {
  path: `${SHOP_MENU_DISPLAY_CATEGORY.path}/add`,
  requiredAuthority: 'CREATE_SHOP_MENU_DISPLAY_CATEGORY'
};
export const SHOP_MENU_DISPLAY_CATEGORY_DETAIL: Route = {
  path: `${SHOP_MENU_DISPLAY_CATEGORY.path}/:shopMenuDisplayCategoryId`,
  requiredAuthority: 'VIEW_SHOP_MENU_DISPLAY_CATEGORY'
};
export const SHOP_MENU_DISPLAY_CATEGORY_EDIT: Route = {
  path: `${SHOP_MENU_DISPLAY_CATEGORY_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_MENU_DISPLAY_CATEGORY'
};

export const SHOP_MENU_INTERNAL_CATEGORY: Route = { path: '/shop-menu-category' };
export const SHOP_MENU_CATEGORY: Route = {
  path: SHOP_MENU_INTERNAL_CATEGORY.path,
  requiredAuthority: 'VIEW_SHOP_MENU_CATEGORY'
};
export const SHOP_MENU_CATEGORY_ADD: Route = {
  path: `${SHOP_MENU_CATEGORY.path}/category/add`,
  requiredAuthority: 'CREATE_SHOP_MENU_CATEGORY'
};
export const SHOP_MENU_CATEGORY_DETAIL: Route = {
  path: `${SHOP_MENU_CATEGORY.path}/category/:shopMenuCategoryId`,
  requiredAuthority: 'VIEW_SHOP_MENU_CATEGORY'
};
export const SHOP_MENU_CATEGORY_EDIT: Route = {
  path: `${SHOP_MENU_CATEGORY_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_MENU_CATEGORY'
};

export const SHOP_MENU_SUBCATEGORY: Route = {
  path: SHOP_MENU_INTERNAL_CATEGORY.path,
  requiredAuthority: 'VIEW_SHOP_MENU_SUBCATEGORY'
};
export const SHOP_MENU_SUBCATEGORY_ADD: Route = {
  path: `${SHOP_MENU_CATEGORY.path}/subcategory/add`,
  requiredAuthority: 'CREATE_SHOP_MENU_SUBCATEGORY'
};
export const SHOP_MENU_SUBCATEGORY_DETAIL: Route = {
  path: `${SHOP_MENU_CATEGORY.path}/subcategory/:shopMenuSubcategoryId`,
  requiredAuthority: 'VIEW_SHOP_MENU_SUBCATEGORY'
};
export const SHOP_MENU_SUBCATEGORY_EDIT: Route = {
  path: `${SHOP_MENU_SUBCATEGORY_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_MENU_SUBCATEGORY'
};

export const SHOP_MENU_CUSTOMIZATION: Route = {
  path: '/shop-menu-customization',
  requiredAuthority: 'VIEW_SHOP_MENU_CUSTOMIZATION'
};
export const SHOP_MENU_CUSTOMIZATION_ADD: Route = {
  path: `${SHOP_MENU_CUSTOMIZATION.path}/add`,
  requiredAuthority: 'CREATE_SHOP_MENU_CUSTOMIZATION'
};
export const SHOP_MENU_CUSTOMIZATION_DETAIL: Route = {
  path: `${SHOP_MENU_CUSTOMIZATION.path}/:shopMenuCustomizationId`,
  requiredAuthority: 'VIEW_SHOP_MENU_CUSTOMIZATION'
};
export const SHOP_MENU_CUSTOMIZATION_EDIT: Route = {
  path: `${SHOP_MENU_CUSTOMIZATION_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_MENU_CUSTOMIZATION'
};

export const SHOP_PRODUCT: Route = {
  path: '/shop-product',
  requiredAuthority: 'VIEW_SHOP_PRODUCT'
};
export const SHOP_PRODUCT_ADD: Route = {
  path: `${SHOP_PRODUCT.path}/add`,
  requiredAuthority: 'CREATE_SHOP_PRODUCT'
};
export const SHOP_PRODUCT_DETAIL: Route = {
  path: `${SHOP_PRODUCT.path}/:shopProductId`,
  requiredAuthority: 'VIEW_SHOP_PRODUCT'
};
export const SHOP_PRODUCT_EDIT: Route = {
  path: `${SHOP_PRODUCT_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_PRODUCT'
};

export const SHOP_PRODUCT_DISCOUNT: Route = { path: `/shop-product-promotion` };
export const SHOP_PRODUCT_DISCOUNT_MAXIMUM_AMOUNT_PER_TRANSACTION_EDIT: Route = {
  path: `${SHOP_PRODUCT_DISCOUNT.path}/maximum-amount/edit`,
  requiredAuthority: 'UPDATE_SHOP_PRODUCT_VOUCHER_SETTING'
};

export const SHOP_PRODUCT_PROMO: Route = {
  path: `${SHOP_PRODUCT_DISCOUNT.path}`,
  requiredAuthority: 'VIEW_SHOP_PRODUCT_PROMO'
};
export const SHOP_PRODUCT_PROMO_ADD: Route = {
  path: `${SHOP_PRODUCT_PROMO.path}/promo/add`,
  requiredAuthority: 'CREATE_SHOP_PRODUCT_PROMO'
};
export const SHOP_PRODUCT_PROMO_DETAIL: Route = {
  path: `${SHOP_PRODUCT_PROMO.path}/promo/:discountId`,
  requiredAuthority: 'VIEW_SHOP_PRODUCT_PROMO'
};
export const SHOP_PRODUCT_PROMO_EDIT: Route = {
  path: `${SHOP_PRODUCT_PROMO_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_PRODUCT_PROMO'
};

export const SHOP_PRODUCT_VOUCHER: Route = {
  path: `${SHOP_PRODUCT_DISCOUNT.path}`,
  requiredAuthority: 'VIEW_SHOP_PRODUCT_VOUCHER_SETTING'
};
export const SHOP_PRODUCT_VOUCHER_ADD: Route = {
  path: `${SHOP_PRODUCT_VOUCHER.path}/voucher/add`,
  requiredAuthority: 'CREATE_SHOP_PRODUCT_VOUCHER_SETTING'
};
export const SHOP_PRODUCT_VOUCHER_DETAIL: Route = {
  path: `${SHOP_PRODUCT_VOUCHER.path}/voucher/:discountId`,
  requiredAuthority: 'VIEW_SHOP_PRODUCT_VOUCHER_SETTING'
};
export const SHOP_PRODUCT_VOUCHER_EDIT: Route = {
  path: `${SHOP_PRODUCT_VOUCHER_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_PRODUCT_VOUCHER_SETTING'
};

export const SHOP_PRODUCT_AUTOMATIC_VOUCHER: Route = {
  path: `${SHOP_PRODUCT_DISCOUNT.path}`,
  requiredAuthority: 'VIEW_SHOP_PRODUCT_VOUCHER_SETTING'
};
export const SHOP_PRODUCT_AUTOMATIC_VOUCHER_ADD: Route = {
  path: `${SHOP_PRODUCT_AUTOMATIC_VOUCHER.path}/automatic-voucher/add`,
  requiredAuthority: 'CREATE_SHOP_PRODUCT_VOUCHER_SETTING'
};
export const SHOP_PRODUCT_AUTOMATIC_VOUCHER_EDIT: Route = {
  path: `${SHOP_PRODUCT_AUTOMATIC_VOUCHER.path}/automatic-voucher/:shopProductVoucherCodeId/edit`,
  requiredAuthority: 'UPDATE_SHOP_PRODUCT_VOUCHER_SETTING'
};
export const SHOP_PRODUCT_ASSIGNED_VOUCHER: Route = {
  path: `${SHOP_PRODUCT_DISCOUNT.path}`,
  requiredAuthority: 'VIEW_SHOP_PRODUCT_ASSIGNED_VOUCHER'
};
export const SHOP_PRODUCT_ASSIGNED_VOUCHER_ADD: Route = {
  path: `${SHOP_PRODUCT_ASSIGNED_VOUCHER.path}/assigned-voucher/add`,
  requiredAuthority: 'CREATE_SHOP_PRODUCT_ASSIGNED_VOUCHER'
};
export const SHOP_PRODUCT_ASSIGNED_VOUCHER_DETAIL: Route = {
  path: `${SHOP_PRODUCT_DISCOUNT.path}/assigned-voucher/:discountId`,
  requiredAuthority: 'VIEW_SHOP_PRODUCT_ASSIGNED_VOUCHER'
};

export const SHOP_PRODUCT_THIRD_PARTY_PROMO: Route = {
  path: `${SHOP_PRODUCT_DISCOUNT.path}`,
  requiredAuthority: 'VIEW_SHOP_PRODUCT_THIRD_PARTY_PROMO'
};
export const SHOP_PRODUCT_THIRD_PARTY_PROMO_ADD: Route = {
  path: `${SHOP_PRODUCT_THIRD_PARTY_PROMO.path}/thirdpartypromo/add`,
  requiredAuthority: 'CREATE_SHOP_PRODUCT_THIRD_PARTY_PROMO'
};
export const SHOP_PRODUCT_THIRD_PARTY_PROMO_DETAIL: Route = {
  path: `${SHOP_PRODUCT_THIRD_PARTY_PROMO.path}/thirdpartypromo/:shopProductThirdPartyPromoId`,
  requiredAuthority: 'VIEW_SHOP_PRODUCT_THIRD_PARTY_PROMO'
};
export const SHOP_PRODUCT_THIRD_PARTY_PROMO_EDIT: Route = {
  path: `${SHOP_PRODUCT_THIRD_PARTY_PROMO_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_PRODUCT_THIRD_PARTY_PROMO'
};

export const SHOP_PRODUCT_DISPLAY_CATEGORY: Route = {
  path: '/shop-product-display-category',
  requiredAuthority: 'VIEW_SHOP_PRODUCT_DISPLAY_CATEGORY'
};
export const SHOP_PRODUCT_DISPLAY_CATEGORY_ADD: Route = {
  path: `${SHOP_PRODUCT_DISPLAY_CATEGORY.path}/add`,
  requiredAuthority: 'CREATE_SHOP_PRODUCT_DISPLAY_CATEGORY'
};
export const SHOP_PRODUCT_DISPLAY_CATEGORY_DETAIL: Route = {
  path: `${SHOP_PRODUCT_DISPLAY_CATEGORY.path}/:shopProductDisplayCategoryId`,
  requiredAuthority: 'VIEW_SHOP_PRODUCT_DISPLAY_CATEGORY'
};
export const SHOP_PRODUCT_DISPLAY_CATEGORY_EDIT: Route = {
  path: `${SHOP_PRODUCT_DISPLAY_CATEGORY_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_PRODUCT_DISPLAY_CATEGORY'
};

export const SHOP_PRODUCT_MACHINE_TYPE_DISPLAY: Route = {
  path: '/shop-product-machine-type-display',
  requiredAuthority: 'VIEW_SHOP_PRODUCT_MACHINE_TYPE_DISPLAY'
};
export const SHOP_PRODUCT_MACHINE_TYPE_DISPLAY_ADD: Route = {
  path: `${SHOP_PRODUCT_MACHINE_TYPE_DISPLAY.path}/add`,
  requiredAuthority: 'CREATE_SHOP_PRODUCT_MACHINE_TYPE_DISPLAY'
};
export const SHOP_PRODUCT_MACHINE_TYPE_DISPLAY_EDIT: Route = {
  path: `${SHOP_PRODUCT_MACHINE_TYPE_DISPLAY.path}/:shopProductMachineTypeDisplayId/edit`,
  requiredAuthority: 'UPDATE_SHOP_PRODUCT_MACHINE_TYPE_DISPLAY'
};
export const SHOP_PRODUCT_MACHINE_TYPE_DISPLAY_DELETE: Route = {
  path: '',
  requiredAuthority: 'DELETE_SHOP_PRODUCT_MACHINE_TYPE_DISPLAY'
};

export const SHOP_DISCOUNT: Route = { path: `/shop-promotion` };
export const SHOP_DISCOUNT_MAXIMUM_AMOUNT_PER_TRANSACTION_EDIT: Route = {
  path: `${SHOP_DISCOUNT.path}/maximum-amount/edit`,
  requiredAuthority: 'UPDATE_SHOP_VOUCHER_SETTING'
};

export const SHOP_PROMO: Route = { path: `${SHOP_DISCOUNT.path}`, requiredAuthority: 'VIEW_SHOP_PROMO' };
export const SHOP_PROMO_ADD: Route = { path: `${SHOP_PROMO.path}/promo/add`, requiredAuthority: 'CREATE_SHOP_PROMO' };
export const SHOP_PROMO_DETAIL: Route = {
  path: `${SHOP_PROMO.path}/promo/:discountId`,
  requiredAuthority: 'VIEW_SHOP_PROMO'
};
export const SHOP_PROMO_EDIT: Route = {
  path: `${SHOP_PROMO_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_PROMO'
};

export const SHOP_TOPPING: Route = { path: `/shop-topping`, requiredAuthority: 'VIEW_SHOP_TOPPING' };
export const SHOP_TOPPING_ADD: Route = { path: `${SHOP_TOPPING.path}/add`, requiredAuthority: 'CREATE_SHOP_TOPPING' };
export const SHOP_TOPPING_DETAIL: Route = {
  path: `${SHOP_TOPPING.path}/:shopToppingId`,
  requiredAuthority: 'VIEW_SHOP_TOPPING'
};
export const SHOP_TOPPING_EDIT: Route = {
  path: `${SHOP_TOPPING_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_TOPPING'
};

export const SHOP_VOUCHER: Route = { path: `${SHOP_DISCOUNT.path}`, requiredAuthority: 'VIEW_SHOP_VOUCHER_SETTING' };
export const SHOP_VOUCHER_ADD: Route = {
  path: `${SHOP_VOUCHER.path}/voucher/add`,
  requiredAuthority: 'CREATE_SHOP_VOUCHER_SETTING'
};
export const SHOP_VOUCHER_DETAIL: Route = {
  path: `${SHOP_VOUCHER.path}/voucher/:discountId`,
  requiredAuthority: 'VIEW_SHOP_VOUCHER_SETTING'
};
export const SHOP_VOUCHER_EDIT: Route = {
  path: `${SHOP_VOUCHER_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_VOUCHER_SETTING'
};

export const SHOP_ASSIGNED_VOUCHER: Route = {
  path: `${SHOP_DISCOUNT.path}`,
  requiredAuthority: 'VIEW_SHOP_ASSIGNED_VOUCHER'
};
export const SHOP_ASSIGNED_VOUCHER_ADD: Route = {
  path: `${SHOP_ASSIGNED_VOUCHER.path}/assigned-voucher/add`,
  requiredAuthority: 'CREATE_SHOP_ASSIGNED_VOUCHER'
};
export const SHOP_ASSIGNED_VOUCHER_DETAIL: Route = {
  path: `${SHOP_DISCOUNT.path}/assigned-voucher/:discountId`,
  requiredAuthority: 'VIEW_SHOP_ASSIGNED_VOUCHER'
};

export const SHOP_AUTOMATIC_VOUCHER: Route = {
  path: `${SHOP_DISCOUNT.path}`,
  requiredAuthority: 'VIEW_SHOP_VOUCHER_SETTING'
};
export const SHOP_AUTOMATIC_VOUCHER_ADD: Route = {
  path: `${SHOP_ASSIGNED_VOUCHER.path}/automatic-voucher/add`,
  requiredAuthority: 'CREATE_SHOP_VOUCHER_SETTING'
};
export const SHOP_AUTOMATIC_VOUCHER_EDIT: Route = {
  path: `${SHOP_ASSIGNED_VOUCHER.path}/automatic-voucher/:shopVoucherCodeId/edit`,
  requiredAuthority: 'UPDATE_SHOP_VOUCHER_SETTING'
};

export const SHOP_THIRD_PARTY_PROMO: Route = {
  path: `${SHOP_DISCOUNT.path}`,
  requiredAuthority: 'VIEW_SHOP_THIRD_PARTY_PROMO'
};
export const SHOP_THIRD_PARTY_PROMO_ADD: Route = {
  path: `${SHOP_THIRD_PARTY_PROMO.path}/thirdpartypromo/add`,
  requiredAuthority: 'CREATE_SHOP_THIRD_PARTY_PROMO'
};
export const SHOP_THIRD_PARTY_PROMO_DETAIL: Route = {
  path: `${SHOP_THIRD_PARTY_PROMO.path}/thirdpartypromo/:shopThirdPartyPromoId`,
  requiredAuthority: 'VIEW_SHOP_THIRD_PARTY_PROMO'
};
export const SHOP_THIRD_PARTY_PROMO_EDIT: Route = {
  path: `${SHOP_THIRD_PARTY_PROMO_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_THIRD_PARTY_PROMO'
};

export const SHOP_POS_PROMOTION: Route = { path: `/shop-pos-promotion` };

export const SHOP_POS_PROMO: Route = {
  path: `${SHOP_POS_PROMOTION.path}`,
  requiredAuthority: 'VIEW_SHOP_POS_PROMO'
};
export const SHOP_POS_PROMO_ADD: Route = {
  path: `${SHOP_POS_PROMO.path}/promo/add`,
  requiredAuthority: 'CREATE_SHOP_POS_PROMO'
};
export const SHOP_POS_PROMO_DETAIL: Route = {
  path: `${SHOP_POS_PROMO.path}/promo/:shopPosPromoId`,
  requiredAuthority: 'VIEW_SHOP_POS_PROMO'
};
export const SHOP_POS_PROMO_EDIT: Route = {
  path: `${SHOP_POS_PROMO_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_POS_PROMO'
};

export const SHOP_POS_PROMO_PARTNER: Route = {
  path: `${SHOP_POS_PROMOTION.path}`,
  requiredAuthority: 'VIEW_SHOP_POS_PROMO_PARTNER'
};
export const SHOP_POS_PROMO_PARTNER_ADD: Route = {
  path: `${SHOP_POS_PROMO_PARTNER.path}/partner/add`,
  requiredAuthority: 'CREATE_SHOP_POS_PROMO_PARTNER'
};
export const SHOP_POS_PROMO_PARTNER_DETAIL: Route = {
  path: `${SHOP_POS_PROMO_PARTNER.path}/partner/:shopPosPromoPartnerId`,
  requiredAuthority: 'VIEW_SHOP_POS_PROMO_PARTNER'
};
export const SHOP_POS_PROMO_PARTNER_EDIT: Route = {
  path: `${SHOP_POS_PROMO_PARTNER_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_POS_PROMO_PARTNER'
};

export const SHOP_POS_SALES_TYPE: Route = {
  path: `/shop-pos-sales-type`,
  requiredAuthority: 'VIEW_SHOP_POS_SALES_TYPE'
};
export const SHOP_POS_SALES_TYPE_ADD: Route = {
  path: `${SHOP_POS_SALES_TYPE.path}/add`,
  requiredAuthority: 'CREATE_SHOP_POS_SALES_TYPE'
};
export const SHOP_POS_SALES_TYPE_DETAIL: Route = {
  path: `${SHOP_POS_SALES_TYPE.path}/:shopPosSalesTypeId`,
  requiredAuthority: 'VIEW_SHOP_POS_SALES_TYPE'
};
export const SHOP_POS_SALES_TYPE_EDIT: Route = {
  path: `${SHOP_POS_SALES_TYPE_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_POS_SALES_TYPE'
};

export const SHOP_FAQ: Route = { path: `/shop-faq`, requiredAuthority: 'VIEW_SHOP_FAQ' };
export const SHOP_FAQ_ADD: Route = { path: `${SHOP_FAQ.path}/add`, requiredAuthority: 'CREATE_SHOP_FAQ' };
export const SHOP_FAQ_EDIT: Route = { path: `${SHOP_FAQ.path}/:shopFaqId/edit`, requiredAuthority: 'UPDATE_SHOP_FAQ' };

export const SHOP_SCROLLING_BANNER: Route = {
  path: `/shop-scrolling-banner`,
  requiredAuthority: 'VIEW_SHOP_SCROLLING_BANNER'
};
export const SHOP_SCROLLING_BANNER_ADD: Route = {
  path: `${SHOP_SCROLLING_BANNER.path}/add`,
  requiredAuthority: 'CREATE_SHOP_SCROLLING_BANNER'
};
export const SHOP_SCROLLING_BANNER_DETAIL: Route = {
  path: `${SHOP_SCROLLING_BANNER.path}/:shopScrollingBannerId`,
  requiredAuthority: 'VIEW_SHOP_SCROLLING_BANNER'
};
export const SHOP_SCROLLING_BANNER_EDIT: Route = {
  path: `${SHOP_SCROLLING_BANNER_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_SCROLLING_BANNER'
};

export const SHOP_HOME_BACKGROUND: Route = {
  path: `/shop-home-background`,
  requiredAuthority: 'VIEW_SHOP_HOME_BACKGROUND'
};
export const SHOP_HOME_BACKGROUND_ADD: Route = {
  path: `${SHOP_HOME_BACKGROUND.path}/add`,
  requiredAuthority: 'CREATE_SHOP_HOME_BACKGROUND'
};
export const SHOP_HOME_BACKGROUND_DETAIL: Route = {
  path: `${SHOP_HOME_BACKGROUND.path}/:shopHomeBackgroundId`,
  requiredAuthority: 'VIEW_SHOP_HOME_BACKGROUND'
};
export const SHOP_HOME_BACKGROUND_EDIT: Route = {
  path: `${SHOP_HOME_BACKGROUND_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_HOME_BACKGROUND'
};
export const SHOP_HOME_BACKGROUND_DELETE: Route = {
  path: '',
  requiredAuthority: 'DELETE_SHOP_HOME_BACKGROUND'
};

export const STOCK_PREPARATION: Route = { path: `/stock-preparation`, requiredAuthority: 'STOCK_PREPARATION' };

export const MENU_SET: Route = { path: '/menu-set', requiredAuthority: 'VIEW_PRODUCT_SET' };
export const MENU_SET_ADD: Route = { path: `${MENU_SET.path}/add`, requiredAuthority: 'CREATE_PRODUCT_SET' };
export const MENU_SET_DETAIL: Route = { path: `${MENU_SET.path}/:productSetId`, requiredAuthority: 'VIEW_PRODUCT_SET' };
export const MENU_SET_EDIT: Route = { path: `${MENU_SET_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_PRODUCT_SET' };

export const MIDTRANS: Route = { path: '/midtrans', requiredAuthority: 'VIEW_MIDTRANS' };
export const MIDTRANS_ADD: Route = { path: `${MIDTRANS.path}/add`, requiredAuthority: 'CREATE_MIDTRANS' };
export const MIDTRANS_DETAIL: Route = { path: `${MIDTRANS.path}/:midtransId`, requiredAuthority: 'VIEW_MIDTRANS' };
export const MIDTRANS_EDIT: Route = { path: `${MIDTRANS_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_MIDTRANS' };

export const PAYMENT_PROVIDER: Route = { path: '/payment-provider', requiredAuthority: 'VIEW_QR_TYPE_MAPPING' };
export const PAYMENT_PROVIDER_DETAIL: Route = {
  path: `${PAYMENT_PROVIDER.path}/:qrTypeMappingId`,
  requiredAuthority: 'VIEW_QR_TYPE_MAPPING'
};
export const PAYMENT_PROVIDER_EDIT: Route = {
  path: `${PAYMENT_PROVIDER_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_QR_TYPE_MAPPING'
};

export const SETTLEMENT_DATA: Route = {
  path: '/settlement-data',
  requiredAuthority: 'VIEW_CARD_READER_SETTLEMENT_DATA'
};
export const SETTLEMENT_FILE: Route = {
  path: '/settlement-file',
  requiredAuthority: 'VIEW_CARD_READER_SETTLEMENT_FILE'
};

export const PROFITABILITY_REPORT: Route = {
  path: '/profitability-report',
  requiredAuthority: 'VIEW_PROFITABILITY_REPORT'
};

export const SHOP_BARISTA: Route = { path: '/shop-barista', requiredAuthority: 'VIEW_LIVE_ORDER' };
export const SHOP_BARISTA_LIVE_ORDER: Route = {
  path: `${SHOP_BARISTA.path}/:hubId/live-order`,
  requiredAuthority: 'VIEW_LIVE_ORDER'
};
export const SHOP_BARISTA_ACCEPT_ORDER: Route = {
  path: `${SHOP_BARISTA.path}/:hubId/live-order`,
  requiredAuthority: 'ACCEPT_ORDER'
};
export const SHOP_BARISTA_FINISH_ORDER: Route = {
  path: `${SHOP_BARISTA.path}/:hubId/live-order`,
  requiredAuthority: 'FINISH_ORDER'
};
export const SHOP_BARISTA_CHANGE_MENU_AVAILABILITY: Route = {
  path: `${SHOP_BARISTA.path}/:hubId/live-order`,
  requiredAuthority: 'CHANGE_MENU_AVAILABILITY'
};

export const SHOP_CUSTOMER: Route = { path: `/shop-customer`, requiredAuthority: 'VIEW_SHOP_CUSTOMER' };
export const SHOP_CUSTOMER_DETAIL: Route = {
  path: `${SHOP_CUSTOMER.path}/:userId`,
  requiredAuthority: 'VIEW_SHOP_CUSTOMER'
};
export const SHOP_CUSTOMER_EDIT: Route = {
  path: `${SHOP_CUSTOMER_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_CUSTOMER'
};

export const SHOP_MEMBERSHIP: Route = { path: `/shop-membership`, requiredAuthority: 'VIEW_SHOP_MEMBERSHIP' };
export const SHOP_MEMBERSHIP_ADD: Route = {
  path: `${SHOP_MEMBERSHIP.path}/add`,
  requiredAuthority: 'CREATE_SHOP_MEMBERSHIP'
};
export const SHOP_MEMBERSHIP_DETAIL: Route = {
  path: `${SHOP_MEMBERSHIP.path}/:membershipId`,
  requiredAuthority: 'VIEW_SHOP_MEMBERSHIP'
};
export const SHOP_MEMBERSHIP_EDIT: Route = {
  path: `${SHOP_MEMBERSHIP_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_MEMBERSHIP'
};
export const SHOP_MEMBERSHIP_QUALIFICATION_DURATION_EDIT: Route = {
  path: `${SHOP_MEMBERSHIP.path}/qualification-duration/edit`,
  requiredAuthority: 'UPDATE_SHOP_MEMBERSHIP'
};

export const SHOP_MEMBERSHIP_PROMO: Route = {
  path: `/shop-membership-promo`,
  requiredAuthority: 'VIEW_SHOP_MEMBERSHIP_PROMO'
};
export const SHOP_MEMBERSHIP_PROMO_ADD: Route = {
  path: `${SHOP_MEMBERSHIP_PROMO.path}/add`,
  requiredAuthority: 'CREATE_SHOP_MEMBERSHIP_PROMO'
};
export const SHOP_MEMBERSHIP_PROMO_DETAIL: Route = {
  path: `${SHOP_MEMBERSHIP_PROMO.path}/:membershipPromoId`,
  requiredAuthority: 'VIEW_SHOP_MEMBERSHIP_PROMO'
};
export const SHOP_MEMBERSHIP_PROMO_EDIT: Route = {
  path: `${SHOP_MEMBERSHIP_PROMO_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_MEMBERSHIP_PROMO'
};

export const SHOP_MENU: Route = { path: `/shop-menu`, requiredAuthority: 'VIEW_SHOP_MENU' };
export const SHOP_MENU_ADD: Route = { path: `${SHOP_MENU.path}/add`, requiredAuthority: 'CREATE_SHOP_MENU' };
export const SHOP_MENU_DETAIL: Route = { path: `${SHOP_MENU.path}/:shopMenuId`, requiredAuthority: 'VIEW_SHOP_MENU' };
export const SHOP_MENU_EDIT: Route = { path: `${SHOP_MENU_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_SHOP_MENU' };
export const SHOP_MENU_INGREDIENT_EDIT: Route = {
  path: `${SHOP_MENU_DETAIL.path}/ingredient/:ingredientId/edit`,
  requiredAuthority: 'UPDATE_SHOP_MENU'
};

export const SHOP_MERCHANT: Route = { path: `/shop-merchant`, requiredAuthority: 'VIEW_SHOP_MERCHANT' };
export const SHOP_MERCHANT_ADD: Route = {
  path: `${SHOP_MERCHANT.path}/add`,
  requiredAuthority: 'CREATE_SHOP_MERCHANT'
};
export const SHOP_MERCHANT_DETAIL: Route = {
  path: `${SHOP_MERCHANT.path}/:merchantId`,
  requiredAuthority: 'VIEW_SHOP_MERCHANT'
};
export const SHOP_MERCHANT_EDIT: Route = {
  path: `${SHOP_MERCHANT_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_MERCHANT'
};

export const SHOP_HUB: Route = { path: `/shop-store`, requiredAuthority: 'VIEW_SHOP_HUB' };
export const SHOP_HUB_DETAIL: Route = { path: `${SHOP_HUB.path}/:hubId`, requiredAuthority: 'VIEW_SHOP_HUB' };
export const SHOP_HUB_ADD: Route = { path: `${SHOP_HUB.path}/add`, requiredAuthority: 'CREATE_SHOP_HUB' };
export const SHOP_HUB_EDIT: Route = { path: `${SHOP_HUB_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_SHOP_HUB' };

export const SHOP_HUB_MENU: Route = { path: `${SHOP_HUB_DETAIL.path}/menu` };
export const SHOP_HUB_MENU_EDIT: Route = {
  path: `${SHOP_HUB_MENU.path}/:menuId/edit`,
  requiredAuthority: 'UPDATE_SHOP_HUB'
};

export const SHOP_HUB_STOCK: Route = { path: `${SHOP_HUB_DETAIL.path}/stock` };
export const SHOP_HUB_STOCK_ADD: Route = { path: `${SHOP_HUB_STOCK.path}/add`, requiredAuthority: 'UPDATE_SHOP_HUB' };
export const SHOP_HUB_STOCK_EDIT: Route = {
  path: `${SHOP_HUB_STOCK.path}/:stockId/edit`,
  requiredAuthority: 'UPDATE_SHOP_HUB'
};
export const SHOP_HUB_STOCK_FILL: Route = { path: `${SHOP_HUB_STOCK.path}/fill`, requiredAuthority: 'UPDATE_SHOP_HUB' };

export const SHOP_DELIVERY_CONFIGURATION: Route = {
  path: `/shop-delivery-configuration`,
  requiredAuthority: 'VIEW_SHOP_DELIVERY_CONFIGURATION'
};
export const SHOP_DELIVERY_CONFIGURATION_EDIT: Route = {
  path: `${SHOP_DELIVERY_CONFIGURATION.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_DELIVERY_CONFIGURATION'
};

export const SHOP_ORDER: Route = { path: '/shop-order', requiredAuthority: 'VIEW_SHOP_ORDER' };
export const SHOP_ORDER_COMPLETED: Route = {
  path: `${SHOP_ORDER.path}/completed`,
  requiredAuthority: 'VIEW_SHOP_ORDER'
};
export const SHOP_ORDER_INCOMPLETED: Route = {
  path: `${SHOP_ORDER.path}/incompleted`,
  requiredAuthority: 'VIEW_SHOP_ORDER'
};
export const SHOP_ORDER_OUTLET_SUMMARY: Route = {
  path: `${SHOP_ORDER.path}/outletsummary`,
  requiredAuthority: 'VIEW_SHOP_ORDER'
};
export const SHOP_ORDER_DETAIL: Route = {
  path: `${SHOP_ORDER.path}/:shopOrderId`,
  requiredAuthority: 'VIEW_SHOP_ORDER'
};

export const SHOP_PARTNER: Route = { path: `/shop-partner`, requiredAuthority: 'VIEW_SHOP_PARTNER' };
export const SHOP_PARTNER_ADD: Route = { path: `${SHOP_PARTNER.path}/add`, requiredAuthority: 'CREATE_SHOP_PARTNER' };
export const SHOP_PARTNER_DETAIL: Route = {
  path: `${SHOP_PARTNER.path}/:shopPartnerId`,
  requiredAuthority: 'VIEW_SHOP_PARTNER'
};
export const SHOP_PARTNER_EDIT: Route = {
  path: `${SHOP_PARTNER_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_PARTNER'
};

export const SHOP_DEAL: Route = { path: `/shop-deal`, requiredAuthority: 'VIEW_SHOP_DEAL' };
export const SHOP_DEAL_ADD: Route = { path: `${SHOP_DEAL.path}/add`, requiredAuthority: 'CREATE_SHOP_DEAL' };
export const SHOP_DEAL_DETAIL: Route = {
  path: `${SHOP_DEAL.path}/:shopDealId`,
  requiredAuthority: 'VIEW_SHOP_DEAL'
};
export const SHOP_DEAL_EDIT: Route = {
  path: `${SHOP_DEAL_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_DEAL'
};
export const SHOP_DEAL_PATH_NAME_EDIT: Route = {
  path: `${SHOP_DEAL_DETAIL.path}/qr/edit`,
  requiredAuthority: 'UPDATE_SHOP_DEAL'
};
export const SHOP_DEAL_REPORT: Route = {
  path: `${SHOP_DEAL.path}/report/:shopDealId`,
  requiredAuthority: 'VIEW_SHOP_DEAL_REPORT'
};

export const SHOP_PAYMENT_PROVIDER: Route = {
  path: '/shop-payment-provider',
  requiredAuthority: 'VIEW_SHOP_PAYMENT_PROVIDER'
};
export const SHOP_PAYMENT_PROVIDER_DETAIL: Route = {
  path: `${SHOP_PAYMENT_PROVIDER.path}/:shopPaymentProviderId`,
  requiredAuthority: 'VIEW_SHOP_PAYMENT_PROVIDER'
};
export const SHOP_PAYMENT_PROVIDER_EDIT: Route = {
  path: `${SHOP_PAYMENT_PROVIDER_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_PAYMENT_PROVIDER'
};

export const SHOP_PWA_CONFIGURATION: Route = {
  path: `/shop-pwa-configuration`,
  requiredAuthority: 'VIEW_SHOP_PWA_CONFIGURATION'
};
export const SHOP_PWA_CONFIGURATION_EDIT: Route = {
  path: `${SHOP_PWA_CONFIGURATION.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_PWA_CONFIGURATION'
};

export const SHOP_TNC: Route = { path: `/shop-tnc`, requiredAuthority: 'VIEW_SHOP_TNC' };
export const SHOP_TNC_EDIT: Route = { path: `${SHOP_TNC.path}/:shopTncId/edit`, requiredAuthority: 'UPDATE_SHOP_TNC' };

export const SHOP_NOTIFICATION: Route = { path: `/shop-notification` };

export const SHOP_MANUAL_NOTIFICATION: Route = {
  path: `${SHOP_NOTIFICATION.path}`,
  requiredAuthority: 'VIEW_SHOP_MANUAL_NOTIFICATION'
};
export const SHOP_MANUAL_NOTIFICATION_DETAIL: Route = {
  path: `${SHOP_MANUAL_NOTIFICATION.path}/manual/:shopNotificationId`,
  requiredAuthority: 'VIEW_SHOP_MANUAL_NOTIFICATION'
};
export const SHOP_MANUAL_NOTIFICATION_ADD: Route = {
  path: `${SHOP_MANUAL_NOTIFICATION.path}/manual/add`,
  requiredAuthority: 'CREATE_SHOP_MANUAL_NOTIFICATION'
};
export const SHOP_MANUAL_NOTIFICATION_EDIT: Route = {
  path: `${SHOP_MANUAL_NOTIFICATION_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_MANUAL_NOTIFICATION'
};

export const SHOP_COURIER: Route = { path: `/internal-courier`, requiredAuthority: 'VIEW_SHOP_COURIER' };
export const SHOP_COURIER_DETAIL: Route = {
  path: `${SHOP_COURIER.path}/:shopCourierId`,
  requiredAuthority: 'VIEW_SHOP_COURIER'
};
export const SHOP_COURIER_ADD: Route = { path: `${SHOP_COURIER.path}/add`, requiredAuthority: 'CREATE_SHOP_COURIER' };
export const SHOP_COURIER_EDIT: Route = {
  path: `${SHOP_COURIER_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_COURIER'
};

export const SHOP_COURIER_ORDER: Route = { path: `/courier-task`, requiredAuthority: 'IS_COURIER' };

export const SHOP_VARIANT: Route = {
  path: `/shop-variant`,
  requiredAuthority: 'VIEW_SHOP_VARIANT'
};
export const SHOP_VARIANT_DETAIL: Route = {
  path: `${SHOP_VARIANT.path}/:shopVariantId`,
  requiredAuthority: 'VIEW_SHOP_VARIANT'
};
export const SHOP_VARIANT_ADD: Route = {
  path: `${SHOP_VARIANT.path}/add`,
  requiredAuthority: 'CREATE_SHOP_VARIANT'
};
export const SHOP_VARIANT_EDIT: Route = {
  path: `${SHOP_VARIANT_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_VARIANT'
};

export const SHOP_POPUP_BANNER: Route = { path: `/shop-popup-banner` };

export const SHOP_MANUAL_POPUP_BANNER: Route = {
  path: `${SHOP_POPUP_BANNER.path}`,
  requiredAuthority: 'VIEW_SHOP_MANUAL_POPUP_BANNER'
};
export const SHOP_MANUAL_POPUP_BANNER_DETAIL: Route = {
  path: `${SHOP_MANUAL_POPUP_BANNER.path}/manual/:shopManualPopupBannerId`,
  requiredAuthority: 'VIEW_SHOP_MANUAL_POPUP_BANNER'
};
export const SHOP_MANUAL_POPUP_BANNER_ADD: Route = {
  path: `${SHOP_MANUAL_POPUP_BANNER.path}/manual/add`,
  requiredAuthority: 'CREATE_SHOP_MANUAL_POPUP_BANNER'
};
export const SHOP_MANUAL_POPUP_BANNER_EDIT: Route = {
  path: `${SHOP_MANUAL_POPUP_BANNER_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_SHOP_MANUAL_POPUP_BANNER'
};

export const SHOP_ABOUT_US: Route = {
  path: '/shop-about-us',
  requiredAuthority: 'VIEW_SHOP_ABOUT_US'
};
export const SHOP_ABOUT_US_EDIT: Route = {
  path: `${SHOP_ABOUT_US.path}/:shopKeyValueId/edit`,
  requiredAuthority: 'UPDATE_SHOP_ABOUT_US'
};

export const SHOP_HOME_ANNOUNCEMENT: Route = {
  path: `/shop-home-announcement`,
  requiredAuthority: 'VIEW_SHOP_HOME_ANNOUNCEMENT'
};
export const SHOP_HOME_ANNOUNCEMENT_EDIT: Route = {
  path: `${SHOP_HOME_ANNOUNCEMENT.path}/:shopHomeAnnouncementId/edit`,
  requiredAuthority: 'UPDATE_SHOP_HOME_ANNOUNCEMENT'
};

export const CARD_CODE: Route = { path: '/card-code', requiredAuthority: 'VIEW_CARD_CODE' };
export const CARD_CODE_ADD: Route = {
  path: '',
  requiredAuthority: 'CREATE_CARD_CODE'
};

export const CLUSTER: Route = { path: '/cluster', requiredAuthority: 'VIEW_CLUSTER' };
export const CLUSTER_ADD: Route = { path: `${CLUSTER.path}/add`, requiredAuthority: 'CREATE_CLUSTER' };
export const CLUSTER_EDIT: Route = {
  path: `${CLUSTER.path}/:clusterId/edit`,
  requiredAuthority: 'UPDATE_CLUSTER'
};

export const DYNAMICS_FAIL_REQUEST: Route = {
  path: `/dynamics-fail-request`,
  requiredAuthority: 'VIEW_DYNAMICS_FAIL_REQUEST'
};
export const DYNAMICS_FAIL_REQUEST_EDIT: Route = {
  path: '',
  requiredAuthority: 'UPDATE_DYNAMICS_FAIL_REQUEST'
};

export const CIMB_DASHBOARD_FAIL_REQUEST: Route = {
  path: `/cimb-dashboard-fail-request`,
  requiredAuthority: 'VIEW_CIMB_DASHBOARD_FAIL_REQUEST'
};
export const CIMB_DASHBOARD_FAIL_REQUEST_EDIT: Route = {
  path: '',
  requiredAuthority: 'UPDATE_CIMB_DASHBOARD_FAIL_REQUEST'
};

export const SHOP_LOYALTY_POINT_HISTORY: Route = {
  path: `/shop-loyalty-point-history`,
  requiredAuthority: 'VIEW_SHOP_LOYALTY_POINT_HISTORY'
};

export const MACHINE_QR: Route = { path: '/machine-qr', requiredAuthority: 'VIEW_MACHINE_QR' };
export const MACHINE_QR_ADD: Route = { path: `${MACHINE_QR.path}/add`, requiredAuthority: 'CREATE_MACHINE_QR' };
export const MACHINE_QR_EDIT: Route = {
  path: `${MACHINE_QR.path}/:machineQrId/edit`,
  requiredAuthority: 'UPDATE_MACHINE_QR'
};

export const DEPARTMENT: Route = { path: '/department', requiredAuthority: 'VIEW_DEPARTMENT' };
export const DEPARTMENT_ADD: Route = { path: `${DEPARTMENT.path}/add`, requiredAuthority: 'CREATE_DEPARTMENT' };
export const DEPARTMENT_EDIT: Route = {
  path: `${DEPARTMENT.path}/:departmentId/edit`,
  requiredAuthority: 'UPDATE_DEPARTMENT'
};

export const REWARD_RULE: Route = { path: '/reward-rule', requiredAuthority: 'VIEW_REWARD_RULE' };
export const REWARD_RULE_ADD: Route = { path: `${REWARD_RULE.path}/add`, requiredAuthority: 'CREATE_REWARD_RULE' };
export const REWARD_RULE_DETAIL: Route = {
  path: `${REWARD_RULE.path}/:rewardRuleId`,
  requiredAuthority: 'VIEW_REWARD_RULE'
};
export const REWARD_RULE_EDIT: Route = {
  path: `${REWARD_RULE_DETAIL.path}/edit`,
  requiredAuthority: 'UPDATE_REWARD_RULE'
};

export const COMPANY: Route = { path: `/company`, requiredAuthority: 'VIEW_COMPANY' };
export const COMPANY_DETAIL: Route = { path: `${COMPANY.path}/:companyId`, requiredAuthority: 'VIEW_COMPANY' };
export const COMPANY_ADD: Route = { path: `${COMPANY.path}/add`, requiredAuthority: 'CREATE_COMPANY' };
export const COMPANY_EDIT: Route = { path: `${COMPANY_DETAIL.path}/edit`, requiredAuthority: 'UPDATE_COMPANY' };
export const COMPANY_DEPARTMENT_DELETE: Route = { path: '', requiredAuthority: 'DELETE_COMPANY_DEPARTMENT' };
export const COMPANY_DEPARTMENT_REWARD_RULE_DELETE: Route = {
  path: '',
  requiredAuthority: 'DELETE_COMPANY_DEPARTMENT_REWARD_RULE'
};

export const RFID_CARD: Route = {
  path: '',
  requiredAuthority: 'VIEW_RFID_CARD'
};
export const RFID_CARD_ADD: Route = {
  path: '',
  requiredAuthority: 'CRETAE_RFID_CARD'
};
export const RFID_CARD_DELETE: Route = {
  path: '',
  requiredAuthority: 'DELETE_RFID_CARD'
};

export const REWARD_ORDER: Route = { path: '/reward-order', requiredAuthority: 'VIEW_REWARD_ORDER' };
