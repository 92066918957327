export const COLOR_BLACK = '#151515';
export const COLOR_BLUE = '#1a5fcd';
export const COLOR_BROWN = '#593823';
export const COLOR_ORANGE = '#faa61a';
export const COLOR_GOLD = '#dcc077';
export const COLOR_GREEN = '#1e9f2d';
export const COLOR_PURE_BLACK = '#000000';
export const COLOR_RED = '#ff0000';
export const COLOR_SILVER = '#c0c0c0';
export const COLOR_WHITE = '#ffffff';

export const COLOR_PRIMARY_OLD = '#f0a600';
export const COLOR_PRIMARY = '#ffda29';
export const COLOR_PRIMARY_DARK = '#ccae20';
export const COLOR_PRIMARY_LIGHT = '#ffe569';
export const COLOR_SECONDARY = COLOR_WHITE;
export const COLOR_ERROR = COLOR_RED;
export const COLOR_SUCCESS = '#27ae60';

export const COLOR_GRAY_BASE = '#373e47';
export const COLOR_GRAY_DARK = '#545454';
export const COLOR_GRAY = '#707070';
export const COLOR_GRAY_LIGHT = '#90a1b8';
export const COLOR_GRAY_LIGHTER = '#d3d9e3';
export const COLOR_GRAY_LIGHTEST = '#f6f6f6';
