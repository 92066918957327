import React from 'react';
import { FormattedMessage } from 'react-intl';

import { HttpStatus, HttpStatusContext } from 'src/app/helpers/http';

export class NotFound extends React.Component {
  render() {
    return (
      <HttpStatusContext.Consumer>
        {(value) => this.renderElement(value)}
      </HttpStatusContext.Consumer>
    );
  }

  private renderElement(value: HttpStatus | undefined) {
    if (value) {
      value.setStatusNotFound();
    }

    return (
      <FormattedMessage id="text.not.found" />
    );
  }
}
