import * as React from 'react';

import { LOCALE_EN } from '@gdp/react-app/lib/components/LocaleProvider';
import { Config as BaseConfig } from '@gdp/react-app/lib/models/config';

export interface Config extends BaseConfig {
  app: {
    graphqlUrl: string;
    pwaDealsUrl: string;
    wsUrl: string;
    googleMaps: {
      deeplinkAndroid: string;
      deeplinkIos: string;
      deeplink: string;
    };
    applyStrictTransportSecurityHeader: string;
    gsdp: {
      url: string;
      secretKey: string;
    };
  };
  locale: string;
}

const { Provider, Consumer } = React.createContext<Config>({
  app: {
    applyStrictTransportSecurityHeader: 'false',
    graphqlUrl: 'http://localhost:8080/api/graphql',
    pwaDealsUrl: 'http://localhost:3101/deals',
    wsUrl: 'ws://localhost:8080/graphql',
    googleMaps: {
      deeplinkAndroid: 'geo:0,0?q=:latitude,:longitude',
      deeplinkIos: 'comgooglemaps://?q=:latitude,:longitude',
      deeplink: 'https://maps.google.com?q=:latitude,:longitude',
    },
    gsdp: {
      url: 'https://jumpstart.glair.ai',
      secretKey: 'gsdpsecretkey',
    },
  },
  locale: LOCALE_EN,
  platform: {
    buildMode: 'development',
    buildNumber: 'development',
    csrUrl: 'http://localhost:3030',
    environment: 'development',
    publicUrl: 'https://localhost:3001',
    ssrUrl: 'http://localhost:3080',
  },
});

export const ConfigContextProvider = Provider;
export const ConfigContextConsumer = Consumer;
export interface ConfigProps {
  config: Config;
}
