import { Loadable } from '@gdp/react-app/lib/helpers/loadable';
import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { NotFound } from 'src/app/components/NotFound';
import {
  ANALYTICS_LOGISTIC,
  ANALYTICS_MACHINE,
  ANALYTICS_OUTLET,
  ANALYTICS_PWA,
  CARD_CODE,
  CIMB_DASHBOARD_FAIL_REQUEST,
  CLUSTER,
  COMPANY,
  CONCISED_ORDER,
  DASHBOARD,
  DEPARTMENT,
  DYNAMICS_FAIL_REQUEST,
  HUB,
  HUB_TYPE,
  INGREDIENT,
  LATTE_CONFIG,
  LEAVE,
  LOGIN,
  MACHINE,
  MACHINE_ERROR,
  MACHINE_PAYMENT,
  MACHINE_QR,
  MACHINE_STATUS_ERROR,
  MACHINE_TYPE,
  MACHINE_TYPE_DISPLAY,
  MENU,
  MENU_SET,
  MIDTRANS,
  ORDER,
  ORDER_LOG,
  ORDER_SUMMARY,
  PACKAGING,
  PAYMENT_PROVIDER,
  PROFILE,
  PROFITABILITY_REPORT,
  REFILLER_SCHEDULE,
  REWARD_ORDER,
  REWARD_RULE,
  ROLE,
  SETTLEMENT_DATA,
  SETTLEMENT_FILE,
  SHOP_ABOUT_US,
  SHOP_BARISTA,
  SHOP_COURIER,
  SHOP_COURIER_ORDER,
  SHOP_CUSTOMER,
  SHOP_DEAL,
  SHOP_DELIVERY_CONFIGURATION,
  SHOP_DISCOUNT,
  SHOP_FAQ,
  SHOP_HOME_ANNOUNCEMENT,
  SHOP_HOME_BACKGROUND,
  SHOP_HUB,
  SHOP_LOYALTY_POINT_HISTORY,
  SHOP_MEMBERSHIP,
  SHOP_MEMBERSHIP_PROMO,
  SHOP_MENU,
  SHOP_MENU_CUSTOMIZATION,
  SHOP_MENU_DISPLAY_CATEGORY,
  SHOP_MENU_INTERNAL_CATEGORY,
  SHOP_MERCHANT,
  SHOP_NOTIFICATION,
  SHOP_ORDER,
  SHOP_PARTNER,
  SHOP_PAYMENT_PROVIDER,
  SHOP_POPUP_BANNER,
  SHOP_POS_PROMOTION,
  SHOP_POS_SALES_TYPE,
  SHOP_PRODUCT,
  SHOP_PRODUCT_DISCOUNT,
  SHOP_PRODUCT_DISPLAY_CATEGORY,
  SHOP_PRODUCT_MACHINE_TYPE_DISPLAY,
  SHOP_PRODUCT_ORDER,
  SHOP_PWA_CONFIGURATION,
  SHOP_SCROLLING_BANNER,
  SHOP_TASK,
  SHOP_TNC,
  SHOP_TOPPING,
  SHOP_VARIANT,
  SPARE_PART,
  STOCK_PREPARATION,
  TASK,
  UNIT,
  USER,
  USER_ACTIVITY
} from 'src/app/constants/route';
import { LoadableClusterRoutes } from 'src/app/containers/authenticated/cluster';
import { LoadableCompanyRoutes } from 'src/app/containers/authenticated/company';
import { LoadableConcisedOrderRoutes } from 'src/app/containers/authenticated/concisedorder';
import { LoadableDepartmentRoutes } from 'src/app/containers/authenticated/department';
import { LoadableHubRoutes } from 'src/app/containers/authenticated/hub';
import { LoadableHubTypeRoutes } from 'src/app/containers/authenticated/hubtype';
import { LoadableIngredientRoutes } from 'src/app/containers/authenticated/ingredient';
import { LoadableLatteConfigRoutes } from 'src/app/containers/authenticated/latteconfig';
import { LoadableLeaveRoutes } from 'src/app/containers/authenticated/leave';
import { LoadableMachineRoutes } from 'src/app/containers/authenticated/machine';
import { LoadableMachineErrorRoutes } from 'src/app/containers/authenticated/machineerror';
import { LoadableMachinePaymentRoutes } from 'src/app/containers/authenticated/machinepayment';
import { LoadableMachineQrRoutes } from 'src/app/containers/authenticated/machineqr';
import { LoadableMachineTypeRoutes } from 'src/app/containers/authenticated/machinetype';
import { LoadableMachineTypeDisplayRoutes } from 'src/app/containers/authenticated/machinetypedisplay';
import { LoadableMidtransRoutes } from 'src/app/containers/authenticated/midtrans';
import { LoadableOrderRoutes } from 'src/app/containers/authenticated/order';
import { LoadableOrderLogRoutes } from 'src/app/containers/authenticated/orderlog';
import { LoadableOrderSummaryReportRoutes } from 'src/app/containers/authenticated/ordersummaryreport';
import { LoadablePackagingRoutes } from 'src/app/containers/authenticated/packaging';
import { LoadableProductRoutes } from 'src/app/containers/authenticated/product';
import { LoadableProductSetRoutes } from 'src/app/containers/authenticated/productset';
import { LoadableProfileRoutes } from 'src/app/containers/authenticated/profile';
import { LoadableRefillerScheduleRoutes } from 'src/app/containers/authenticated/refillerschedule';
import { LoadableRewardRuleRoutes } from 'src/app/containers/authenticated/rewardrule';
import { LoadableRoleRoutes } from 'src/app/containers/authenticated/role';
import { LoadableShopAboutUsRoutes } from 'src/app/containers/authenticated/shopaboutus';
import { LoadableShopBaristaRoutes } from 'src/app/containers/authenticated/shopbarista';
import { LoadableShopCourierRoutes } from 'src/app/containers/authenticated/shopcourier';
import { LoadableShopCustomerRoutes } from 'src/app/containers/authenticated/shopcustomer';
import { LoadableShopDealRoutes } from 'src/app/containers/authenticated/shopdeal';
import { LoadableShopDeliveryConfigurationRoutes } from 'src/app/containers/authenticated/shopdeliveryconfiguration';
import { LoadableShopDiscountRoutes } from 'src/app/containers/authenticated/shopdiscount';
import { LoadableShopFaqRoutes } from 'src/app/containers/authenticated/shopfaq';
import { LoadableShopHomeAnnouncementRoutes } from 'src/app/containers/authenticated/shophomeannouncement';
import { LoadableShopHomeBackgroundRoutes } from 'src/app/containers/authenticated/shophomebackground';
import { LoadableShopHubRoutes } from 'src/app/containers/authenticated/shophub';
import { LoadableShopMembershipRoutes } from 'src/app/containers/authenticated/shopmembership';
import { LoadableShopMembershipPromoRoutes } from 'src/app/containers/authenticated/shopmembershippromo';
import { LoadableShopMenuRoutes } from 'src/app/containers/authenticated/shopmenu';
import { LoadableShopMenuCustomizationRoutes } from 'src/app/containers/authenticated/shopmenucustomization';
import { LoadableShopMenuDisplayCategoryRoutes } from 'src/app/containers/authenticated/shopmenudisplaycategory';
import { LoadableShopMenuInternalCategoryRoutes } from 'src/app/containers/authenticated/shopmenuinternalcategory';
import { LoadableShopMerchantRoutes } from 'src/app/containers/authenticated/shopmerchant';
import { LoadableShopNotificationRoutes } from 'src/app/containers/authenticated/shopnotification';
import { LoadableShopOrderRoutes } from 'src/app/containers/authenticated/shoporder';
import { LoadableShopPartnerRoutes } from 'src/app/containers/authenticated/shoppartner';
import { LoadableShopPopupBannerRoutes } from 'src/app/containers/authenticated/shoppopupbanner';
import { LoadableShopPosPromotionRoutes } from 'src/app/containers/authenticated/shoppospromotion';
import { LoadableShopPosSalesTypeRoutes } from 'src/app/containers/authenticated/shoppossalestype';
import { LoadableShopProductRoutes } from 'src/app/containers/authenticated/shopproduct';
import { LoadableShopProductDiscountRoutes } from 'src/app/containers/authenticated/shopproductdiscount';
import { LoadableShopProductDisplayCategoryRoutes } from 'src/app/containers/authenticated/shopproductdisplaycategory';
import { LoadableShopProductMachineTypeDisplayRoutes } from 'src/app/containers/authenticated/shopproductmachinetypedisplay';
import { LoadableShopProductOrderRoutes } from 'src/app/containers/authenticated/shopproductorder';
import { LoadableShopPwaConfigurationRoutes } from 'src/app/containers/authenticated/shoppwaconfiguration';
import { LoadableShopScrollingBannerRoutes } from 'src/app/containers/authenticated/shopscrollingbanner';
import { LoadableShopTaskRoutes } from 'src/app/containers/authenticated/shoptask';
import { LoadableShopTncRoutes } from 'src/app/containers/authenticated/shoptnc';
import { LoadableShopToppingRoutes } from 'src/app/containers/authenticated/shoptopping';
import { LoadableShopVariantRoutes } from 'src/app/containers/authenticated/shopvariant';
import { LoadableSparePartRoutes } from 'src/app/containers/authenticated/sparepart';
import { LoadableStockPreparationRoutes } from 'src/app/containers/authenticated/stockpreparation';
import { LoadableTaskRoutes } from 'src/app/containers/authenticated/task';
import { LoadableUnitRoutes } from 'src/app/containers/authenticated/unit';
import { LoadableUserRoutes } from 'src/app/containers/authenticated/user';

const routes = [
  {
    component: Loadable(
      '../../containers/Login',
      () => import(/* webpackChunkName: "Login" */ '../../containers/Login'),
      () => [(require as any).resolveWeak('../../containers/Login')]
    ),
    isExact: true,
    path: LOGIN.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/Dashboard',
      () => import(/* webpackChunkName: "Dashboard" */ '../../containers/authenticated/Dashboard'),
      () => [(require as any).resolveWeak('../../containers/authenticated/Dashboard')]
    ),
    isExact: true,
    path: DASHBOARD.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/analytics/LogisticAnalytics',
      () =>
        import(
          /* webpackChunkName: "LogisticAnalytics" */ '../../containers/authenticated/analytics/LogisticAnalytics'
        ),
      () => [(require as any).resolveWeak('../../containers/authenticated/analytics/LogisticAnalytics')]
    ),
    isExact: true,
    path: ANALYTICS_LOGISTIC.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/analytics/MachineAnalytics',
      () =>
        import(/* webpackChunkName: "MachineAnalytics" */ '../../containers/authenticated/analytics/MachineAnalytics'),
      () => [(require as any).resolveWeak('../../containers/authenticated/analytics/MachineAnalytics')]
    ),
    isExact: true,
    path: ANALYTICS_MACHINE.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/analytics/OutletAnalytics',
      () =>
        import(/* webpackChunkName: "OutletAnalytics" */ '../../containers/authenticated/analytics/OutletAnalytics'),
      () => [(require as any).resolveWeak('../../containers/authenticated/analytics/OutletAnalytics')]
    ),
    isExact: true,
    path: ANALYTICS_OUTLET.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/analytics/PwaAnalytics',
      () => import(/* webpackChunkName: "PwaAnalytics" */ '../../containers/authenticated/analytics/PwaAnalytics'),
      () => [(require as any).resolveWeak('../../containers/authenticated/analytics/PwaAnalytics')]
    ),
    isExact: true,
    path: ANALYTICS_PWA.path
  },
  {
    component: LoadableHubTypeRoutes,
    isExact: false,
    path: HUB_TYPE.path
  },
  {
    component: LoadableIngredientRoutes,
    isExact: false,
    path: INGREDIENT.path
  },
  {
    component: LoadableLeaveRoutes,
    isExact: false,
    path: LEAVE.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/machinestatuserror/MachineStatusErrorList',
      () =>
        import(
          /* webpackChunkName: "MachineStatusErrorList" */ '../../containers/authenticated/machinestatuserror/MachineStatusErrorList'
        ),
      () => [(require as any).resolveWeak('../../containers/authenticated/machinestatuserror/MachineStatusErrorList')]
    ),
    isExact: true,
    path: MACHINE_STATUS_ERROR.path
  },
  {
    component: LoadableMachineRoutes,
    isExact: false,
    path: MACHINE.path
  },
  {
    component: LoadableMachineTypeRoutes,
    isExact: false,
    path: MACHINE_TYPE.path
  },
  {
    component: LoadableMachineTypeDisplayRoutes,
    isExact: false,
    path: MACHINE_TYPE_DISPLAY.path
  },
  {
    component: LoadableProductRoutes,
    isExact: false,
    path: MENU.path
  },
  {
    component: LoadableShopVariantRoutes,
    isExact: false,
    path: SHOP_VARIANT.path
  },
  {
    component: LoadableShopMenuCustomizationRoutes,
    isExact: false,
    path: SHOP_MENU_CUSTOMIZATION.path
  },
  {
    component: LoadableOrderRoutes,
    isExact: false,
    path: ORDER.path
  },
  {
    component: LoadableShopProductOrderRoutes,
    isExact: false,
    path: SHOP_PRODUCT_ORDER.path
  },
  {
    component: LoadableConcisedOrderRoutes,
    isExact: false,
    path: CONCISED_ORDER.path
  },
  {
    component: LoadableOrderLogRoutes,
    isExact: false,
    path: ORDER_LOG.path
  },
  {
    component: LoadableOrderSummaryReportRoutes,
    isExact: false,
    path: ORDER_SUMMARY.path
  },
  {
    component: LoadablePackagingRoutes,
    isExact: false,
    path: PACKAGING.path
  },
  {
    component: LoadableMachinePaymentRoutes,
    isExact: false,
    path: MACHINE_PAYMENT.path
  },
  {
    component: LoadableProfileRoutes,
    isExact: false,
    path: PROFILE.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/profitabilityreport/List/ProfitabilityReportList',
      () =>
        import(
          /* webpackChunkName: "ProfitabilityReportList" */ '../../containers/authenticated/profitabilityreport/List/ProfitabilityReportList'
        ),
      () => [
        (require as any).resolveWeak('../../containers/authenticated/profitabilityreport/List/ProfitabilityReportList')
      ]
    ),
    isExact: true,
    path: PROFITABILITY_REPORT.path
  },
  {
    component: LoadableMachineQrRoutes,
    isExact: false,
    path: MACHINE_QR.path
  },
  {
    component: LoadableRoleRoutes,
    isExact: false,
    path: ROLE.path
  },
  {
    component: LoadableStockPreparationRoutes,
    isExact: false,
    path: STOCK_PREPARATION.path
  },
  {
    component: LoadableShopMenuInternalCategoryRoutes,
    isExact: false,
    path: SHOP_MENU_INTERNAL_CATEGORY.path
  },
  {
    component: LoadableShopMenuDisplayCategoryRoutes,
    isExact: false,
    path: SHOP_MENU_DISPLAY_CATEGORY.path
  },
  {
    component: LoadableUserRoutes,
    isExact: false,
    path: USER.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/useractivity/List/UserActivityList',
      () =>
        import(
          /* webpackChunkName: "UserActivityList" */ '../../containers/authenticated/useractivity/List/UserActivityList'
        ),
      () => [(require as any).resolveWeak('../../containers/authenticated/useractivity/List/UserActivityList')]
    ),
    isExact: true,
    path: USER_ACTIVITY.path
  },
  {
    component: LoadableUnitRoutes,
    isExact: false,
    path: UNIT.path
  },
  {
    component: LoadableTaskRoutes,
    isExact: false,
    path: TASK.path
  },
  {
    component: LoadableShopTaskRoutes,
    isExact: false,
    path: SHOP_TASK.path
  },
  {
    component: LoadableRefillerScheduleRoutes,
    isExact: false,
    path: REFILLER_SCHEDULE.path
  },
  {
    component: LoadableProductSetRoutes,
    isExact: false,
    path: MENU_SET.path
  },
  {
    component: LoadableMidtransRoutes,
    isExact: false,
    path: MIDTRANS.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/paymentprovider/List/PaymentProviderList',
      () =>
        import(
          /* webpackChunkName: "PaymentProviderList" */ '../../containers/authenticated/paymentprovider/List/PaymentProviderList'
        ),
      () => [(require as any).resolveWeak('../../containers/authenticated/paymentprovider/List/PaymentProviderList')]
    ),
    isExact: true,
    path: PAYMENT_PROVIDER.path
  },
  {
    component: LoadableLatteConfigRoutes,
    isExact: false,
    path: LATTE_CONFIG.path
  },
  {
    component: LoadableShopBaristaRoutes,
    isExact: false,
    path: SHOP_BARISTA.path
  },
  {
    component: LoadableShopCustomerRoutes,
    isExact: false,
    path: SHOP_CUSTOMER.path
  },
  {
    component: LoadableShopMembershipRoutes,
    isExact: false,
    path: SHOP_MEMBERSHIP.path
  },
  {
    component: LoadableShopMembershipPromoRoutes,
    isExact: false,
    path: SHOP_MEMBERSHIP_PROMO.path
  },
  {
    component: LoadableShopScrollingBannerRoutes,
    isExact: false,
    path: SHOP_SCROLLING_BANNER.path
  },
  {
    component: LoadableShopHomeBackgroundRoutes,
    isExact: false,
    path: SHOP_HOME_BACKGROUND.path
  },
  {
    component: LoadableShopMenuRoutes,
    isExact: false,
    path: SHOP_MENU.path
  },
  {
    component: LoadableShopProductRoutes,
    isExact: false,
    path: SHOP_PRODUCT.path
  },
  {
    component: LoadableShopProductDiscountRoutes,
    isExact: false,
    path: SHOP_PRODUCT_DISCOUNT.path
  },
  {
    component: LoadableShopProductDisplayCategoryRoutes,
    isExact: false,
    path: SHOP_PRODUCT_DISPLAY_CATEGORY.path
  },
  {
    component: LoadableShopProductMachineTypeDisplayRoutes,
    isExact: false,
    path: SHOP_PRODUCT_MACHINE_TYPE_DISPLAY.path
  },
  {
    component: LoadableShopDiscountRoutes,
    isExact: false,
    path: SHOP_DISCOUNT.path
  },
  {
    component: LoadableShopMerchantRoutes,
    isExact: false,
    path: SHOP_MERCHANT.path
  },
  {
    component: LoadableShopHubRoutes,
    isExact: false,
    path: SHOP_HUB.path
  },
  {
    component: LoadableShopFaqRoutes,
    isExact: false,
    path: SHOP_FAQ.path
  },
  {
    component: LoadableShopOrderRoutes,
    isExact: false,
    path: SHOP_ORDER.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/shoppaymentprovider/List/ShopPaymentProviderList',
      () =>
        import(
          /* webpackChunkName: "ShopPaymentProviderList" */ '../../containers/authenticated/shoppaymentprovider/List/ShopPaymentProviderList'
        ),
      () => [
        (require as any).resolveWeak('../../containers/authenticated/shoppaymentprovider/List/ShopPaymentProviderList')
      ]
    ),
    isExact: true,
    path: SHOP_PAYMENT_PROVIDER.path
  },
  {
    component: LoadableShopDeliveryConfigurationRoutes,
    isExact: false,
    path: SHOP_DELIVERY_CONFIGURATION.path
  },
  {
    component: LoadableShopPwaConfigurationRoutes,
    isExact: false,
    path: SHOP_PWA_CONFIGURATION.path
  },
  {
    component: LoadableShopTncRoutes,
    isExact: false,
    path: SHOP_TNC.path
  },
  {
    component: LoadableShopNotificationRoutes,
    isExact: false,
    path: SHOP_NOTIFICATION.path
  },
  {
    component: LoadableShopPartnerRoutes,
    isExact: false,
    path: SHOP_PARTNER.path
  },
  {
    component: LoadableShopPosPromotionRoutes,
    isExact: false,
    path: SHOP_POS_PROMOTION.path
  },
  {
    component: LoadableShopDealRoutes,
    isExact: false,
    path: SHOP_DEAL.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/settlementdata/List/SettlementDataList',
      () => import('../../containers/authenticated/settlementdata/List/SettlementDataList'),
      () => [(require as any).resolveWeak('../../containers/authenticated/settlementdata/List/SettlementDataList')]
    ),
    isExact: true,
    path: SETTLEMENT_DATA.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/settlementdata/List/SettlementFileList',
      () => import('../../containers/authenticated/settlementdata/List/SettlementFileList'),
      () => [(require as any).resolveWeak('../../containers/authenticated/settlementdata/List/SettlementFileList')]
    ),
    isExact: true,
    path: SETTLEMENT_FILE.path
  },
  {
    component: LoadableShopCourierRoutes,
    isExact: false,
    path: SHOP_COURIER.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/shopcourierorder/List/ShopCourierOrderList',
      () =>
        import(
          /* webpackChunkName: "ShopCourierOrderList" */ '../../containers/authenticated/shopcourierorder/List/ShopCourierOrderList'
        ),
      () => [(require as any).resolveWeak('../../containers/authenticated/shopcourierorder/List/ShopCourierOrderList')]
    ),
    isExact: true,
    path: SHOP_COURIER_ORDER.path
  },
  {
    component: LoadableHubRoutes,
    isExact: false,
    path: HUB.path
  },
  {
    component: LoadableShopToppingRoutes,
    isExact: false,
    path: SHOP_TOPPING.path
  },
  {
    component: LoadableShopPopupBannerRoutes,
    isExact: false,
    path: SHOP_POPUP_BANNER.path
  },
  {
    component: LoadableShopAboutUsRoutes,
    isExact: false,
    path: SHOP_ABOUT_US.path
  },
  {
    component: LoadableShopHomeAnnouncementRoutes,
    isExact: false,
    path: SHOP_HOME_ANNOUNCEMENT.path
  },
  {
    component: LoadableSparePartRoutes,
    isExact: false,
    path: SPARE_PART.path
  },
  {
    component: LoadableMachineErrorRoutes,
    isExact: false,
    path: MACHINE_ERROR.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/cardcode/List/CardCodeList',
      () => import(/* webpackChunkName: "CardCodeList" */ '../../containers/authenticated/cardcode/List/CardCodeList'),
      () => [(require as any).resolveWeak('../../containers/authenticated/cardcode/List/CardCodeList')]
    ),
    isExact: true,
    path: CARD_CODE.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/dynamicsfailrequest/List/DynamicsFailRequestList',
      () =>
        import(
          /* webpackChunkName: "DynamicsFailRequestList" */ '../../containers/authenticated/dynamicsfailrequest/List/DynamicsFailRequestList'
        ),
      () => [
        (require as any).resolveWeak('../../containers/authenticated/dynamicsfailrequest/List/DynamicsFailRequestList')
      ]
    ),
    isExact: true,
    path: DYNAMICS_FAIL_REQUEST.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/cimbdashboardfailrequest/List/CimbDashboardFailRequestList',
      () =>
        import(
          /* webpackChunkName: "CimbDashboardFailRequestList" */ '../../containers/authenticated/cimbdashboardfailrequest/List/CimbDashboardFailRequestList'
        ),
      () => [
        (require as any).resolveWeak(
          '../../containers/authenticated/cimbdashboardfailrequest/List/CimbDashboardFailRequestList'
        )
      ]
    ),
    isExact: true,
    path: CIMB_DASHBOARD_FAIL_REQUEST.path
  },
  {
    component: LoadableClusterRoutes,
    isExact: false,
    path: CLUSTER.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/shoployaltypointhistory/List/ShopLoyaltyPointHistoryList',
      () =>
        import(
          /* webpackChunkName: "ShopLoyaltyPointHistoryList" */ '../../containers/authenticated/shoployaltypointhistory/List/ShopLoyaltyPointHistoryList'
        ),
      () => [
        (require as any).resolveWeak(
          '../../containers/authenticated/shoployaltypointhistory/List/ShopLoyaltyPointHistoryList'
        )
      ]
    ),
    isExact: true,
    path: SHOP_LOYALTY_POINT_HISTORY.path
  },
  {
    component: LoadableShopPosSalesTypeRoutes,
    isExact: false,
    path: SHOP_POS_SALES_TYPE.path
  },
  {
    component: LoadableDepartmentRoutes,
    isExact: false,
    path: DEPARTMENT.path
  },
  {
    component: LoadableRewardRuleRoutes,
    isExact: false,
    path: REWARD_RULE.path
  },
  {
    component: LoadableCompanyRoutes,
    isExact: false,
    path: COMPANY.path
  },
  {
    component: Loadable(
      '../../containers/authenticated/rewardorder/List/RewardOrderList',
      () =>
        import(/* webpackChunkName: "RewardOrder" */ '../../containers/authenticated/rewardorder/List/RewardOrderList'),
      () => [(require as any).resolveWeak('../../containers/authenticated/rewardorder/List/RewardOrderList')]
    ),
    isExact: true,
    path: REWARD_ORDER.path
  }
];

@withStyles(require('./Routes.scss'))
export class Routes extends React.Component {
  render() {
    return (
      <Switch>
        {// tslint:disable-next-line: jsx-no-multiline-js
        routes.map((r: any, index: number) => (
          <Route key={index} path={r.path} exact={r.isExact} component={r.component} />
        ))}
        <Route component={NotFound} />
      </Switch>
    );
  }
}
