import { LayoutActionCreators } from 'src/app/actions/layout';
import { LayoutState } from 'src/app/models/layout';

export const initialState: LayoutState = {
  isLoading: false,
};

type Action = (typeof LayoutActionCreators)[keyof typeof LayoutActionCreators];

export function layoutReducer(state = initialState, action: Action): typeof initialState {
  switch (action.type) {
    case LayoutActionCreators.hideLoading.type:
      return { ...state, isLoading: false };
    case LayoutActionCreators.showLoading.type:
      return { ...state, isLoading: true };
    default:
      return state;
  }
}
